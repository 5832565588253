const cities = [
    { id: 1, city: 'Kolhapur', state: 'Maharashtra' },
    { id: 2, city: 'Port Blair', state: 'Andaman & Nicobar Islands' },
    { id: 3, city: 'Adilabad', state: 'Andhra Pradesh' },
    { id: 4, city: 'Adoni', state: 'Andhra Pradesh' },
    { id: 5, city: 'Amadalavalasa', state: 'Andhra Pradesh' },
    { id: 6, city: 'Amalapuram', state: 'Andhra Pradesh' },
    { id: 7, city: 'Anakapalle', state: 'Andhra Pradesh' },
    { id: 8, city: 'Anantapur', state: 'Andhra Pradesh' },
    { id: 9, city: 'Badepalle', state: 'Andhra Pradesh' },
    { id: 10, city: 'Banganapalle', state: 'Andhra Pradesh' },
    { id: 11, city: 'Bapatla', state: 'Andhra Pradesh' },
    { id: 12, city: 'Bellampalle', state: 'Andhra Pradesh' },
    { id: 13, city: 'Bethamcherla', state: 'Andhra Pradesh' },
    { id: 14, city: 'Bhadrachalam', state: 'Andhra Pradesh' },
    { id: 15, city: 'Bhainsa', state: 'Andhra Pradesh' },
    { id: 16, city: 'Bheemunipatnam', state: 'Andhra Pradesh' },
    { id: 17, city: 'Bhimavaram', state: 'Andhra Pradesh' },
    { id: 18, city: 'Bhongir', state: 'Andhra Pradesh' },
    { id: 19, city: 'Bobbili', state: 'Andhra Pradesh' },
    { id: 20, city: 'Bodhan', state: 'Andhra Pradesh' },
    { id: 21, city: 'Chilakaluripet', state: 'Andhra Pradesh' },
    { id: 22, city: 'Chirala', state: 'Andhra Pradesh' },
    { id: 23, city: 'Chittoor', state: 'Andhra Pradesh' },
    { id: 24, city: 'Cuddapah', state: 'Andhra Pradesh' },
    { id: 25, city: 'Devarakonda', state: 'Andhra Pradesh' },
    { id: 26, city: 'Dharmavaram', state: 'Andhra Pradesh' },
    { id: 27, city: 'Eluru', state: 'Andhra Pradesh' },
    { id: 28, city: 'Farooqnagar', state: 'Andhra Pradesh' },
    { id: 29, city: 'Gadwal', state: 'Andhra Pradesh' },
    { id: 30, city: 'Gooty', state: 'Andhra Pradesh' },
    { id: 31, city: 'Gudivada', state: 'Andhra Pradesh' },
    { id: 32, city: 'Gudur', state: 'Andhra Pradesh' },
    { id: 33, city: 'Guntakal', state: 'Andhra Pradesh' },
    { id: 34, city: 'Guntur', state: 'Andhra Pradesh' },
    { id: 35, city: 'Hanuman Junction', state: 'Andhra Pradesh' },
    { id: 36, city: 'Hindupur', state: 'Andhra Pradesh' },
    { id: 37, city: 'Hyderabad', state: 'Andhra Pradesh' },
    { id: 38, city: 'Ichchapuram', state: 'Andhra Pradesh' },
    { id: 39, city: 'Jaggaiahpet', state: 'Andhra Pradesh' },
    { id: 40, city: 'Jagtial', state: 'Andhra Pradesh' },
    { id: 41, city: 'Jammalamadugu', state: 'Andhra Pradesh' },
    { id: 42, city: 'Jangaon', state: 'Andhra Pradesh' },
    { id: 43, city: 'Kadapa', state: 'Andhra Pradesh' },
    { id: 44, city: 'Kadiri', state: 'Andhra Pradesh' },
    { id: 45, city: 'Kagaznagar', state: 'Andhra Pradesh' },
    { id: 46, city: 'Kakinada', state: 'Andhra Pradesh' },
    { id: 47, city: 'Kalyandurg', state: 'Andhra Pradesh' },
    { id: 48, city: 'Kamareddy', state: 'Andhra Pradesh' },
    { id: 49, city: 'Kandukur', state: 'Andhra Pradesh' },
    { id: 50, city: 'Karimnagar', state: 'Andhra Pradesh' },
    { id: 51, city: 'Kavali', state: 'Andhra Pradesh' },
    { id: 52, city: 'Khammam', state: 'Andhra Pradesh' },
    { id: 53, city: 'Koratla', state: 'Andhra Pradesh' },
    { id: 54, city: 'Kothagudem', state: 'Andhra Pradesh' },
    { id: 55, city: 'Kothapeta', state: 'Andhra Pradesh' },
    { id: 56, city: 'Kovvur', state: 'Andhra Pradesh' },
    { id: 57, city: 'Kurnool', state: 'Andhra Pradesh' },
    { id: 58, city: 'Kyathampalle', state: 'Andhra Pradesh' },
    { id: 59, city: 'Macherla', state: 'Andhra Pradesh' },
    { id: 60, city: 'Machilipatnam', state: 'Andhra Pradesh' },
    { id: 61, city: 'Madanapalle', state: 'Andhra Pradesh' },
    { id: 62, city: 'Mahbubnagar', state: 'Andhra Pradesh' },
    { id: 63, city: 'Mancherial', state: 'Andhra Pradesh' },
    { id: 64, city: 'Mandamarri', state: 'Andhra Pradesh' },
    { id: 65, city: 'Mandapeta', state: 'Andhra Pradesh' },
    { id: 66, city: 'Manuguru', state: 'Andhra Pradesh' },
    { id: 67, city: 'Markapur', state: 'Andhra Pradesh' },
    { id: 68, city: 'Medak', state: 'Andhra Pradesh' },
    { id: 69, city: 'Miryalaguda', state: 'Andhra Pradesh' },
    { id: 70, city: 'Mogalthur', state: 'Andhra Pradesh' },
    { id: 71, city: 'Nagari', state: 'Andhra Pradesh' },
    { id: 72, city: 'Nagarkurnool', state: 'Andhra Pradesh' },
    { id: 73, city: 'Nandyal', state: 'Andhra Pradesh' },
    { id: 74, city: 'Narasapur', state: 'Andhra Pradesh' },
    { id: 75, city: 'Narasaraopet', state: 'Andhra Pradesh' },
    { id: 76, city: 'Narayanpet', state: 'Andhra Pradesh' },
    { id: 77, city: 'Narsipatnam', state: 'Andhra Pradesh' },
    { id: 78, city: 'Nellore', state: 'Andhra Pradesh' },
    { id: 79, city: 'Navole', state: 'Andhra Pradesh' },
    { id: 80, city: 'Nirmal', state: 'Andhra Pradesh' },
    { id: 81, city: 'Nizamabad', state: 'Andhra Pradesh' },
    { id: 82, city: 'Nuzv state', state: 'Andhra Pradesh' },
    { id: 83, city: 'Ongole', state: 'Andhra Pradesh' },
    { id: 84, city: 'Palacole', state: 'Andhra Pradesh' },
    { id: 85, city: 'Palasa Kasibugga', state: 'Andhra Pradesh' },
    { id: 86, city: 'Palwancha', state: 'Andhra Pradesh' },
    { id: 87, city: 'Parvathipuram', state: 'Andhra Pradesh' },
    { id: 88, city: 'Pedana', state: 'Andhra Pradesh' },
    { id: 89, city: 'Peddapuram', state: 'Andhra Pradesh' },
    { id: 90, city: 'Pithapuram', state: 'Andhra Pradesh' },
    { id: 91, city: 'Pondur', state: 'Andhra pradesh' },
    { id: 92, city: 'Ponnur', state: 'Andhra Pradesh' },
    { id: 93, city: 'Proddatur', state: 'Andhra Pradesh' },
    { id: 94, city: 'Punganur', state: 'Andhra Pradesh' },
    { id: 95, city: 'Puttur', state: 'Andhra Pradesh' },
    { id: 96, city: 'Rajahmundry', state: 'Andhra Pradesh' },
    { id: 97, city: 'Rajam', state: 'Andhra Pradesh' },
    { id: 98, city: 'Ramachandrapuram', state: 'Andhra Pradesh' },
    { id: 99, city: 'Ramagundam', state: 'Andhra Pradesh' },
    { id: 100, city: 'Rayachoti', state: 'Andhra Pradesh' },
    { id: 101, city: 'Rayadurg', state: 'Andhra Pradesh' },
    { id: 102, city: 'Renigunta', state: 'Andhra Pradesh' },
    { id: 103, city: 'Repalle', state: 'Andhra Pradesh' },
    { id: 104, city: 'Sadasivpet', state: 'Andhra Pradesh' },
    { id: 105, city: 'Salur', state: 'Andhra Pradesh' },
    { id: 106, city: 'Samalkot', state: 'Andhra Pradesh' },
    { id: 107, city: 'Sangareddy', state: 'Andhra Pradesh' },
    { id: 108, city: 'Sattenapalle', state: 'Andhra Pradesh' },
    { id: 109, city: 'Spet', state: 'Andhra Pradesh' },
    { id: 110, city: 'Singapur', state: 'Andhra Pradesh' },
    { id: 111, city: 'Sircilla', state: 'Andhra Pradesh' },
    { id: 112, city: 'Srikakulam', state: 'Andhra Pradesh' },
    { id: 113, city: 'Srikalahasti', state: 'Andhra Pradesh' },
    { id: 114, city: 'Suryapet', state: 'Andhra Pradesh' },
    { id: 115, city: 'Tadepalligudem', state: 'Andhra Pradesh' },
    { id: 116, city: 'Tadpatri', state: 'Andhra Pradesh' },
    { id: 117, city: 'Tandur', state: 'Andhra Pradesh' },
    { id: 118, city: 'Tanuku', state: 'Andhra Pradesh' },
    { id: 119, city: 'Tenali', state: 'Andhra Pradesh' },
    { id: 120, city: 'Tirupati', state: 'Andhra Pradesh' },
    { id: 121, city: 'Tuni', state: 'Andhra Pradesh' },
    { id: 122, city: 'Uravakonda', state: 'Andhra Pradesh' },
    { id: 123, city: 'Venkatagiri', state: 'Andhra Pradesh' },
    { id: 124, city: 'Vicarabad', state: 'Andhra Pradesh' },
    { id: 125, city: 'Vijayawada', state: 'Andhra Pradesh' },
    { id: 126, city: 'Vinukonda', state: 'Andhra Pradesh' },
    { id: 127, city: 'Visakhapatnam', state: 'Andhra Pradesh' },
    { id: 128, city: 'Vizianagaram', state: 'Andhra Pradesh' },
    { id: 129, city: 'Wanaparthy', state: 'Andhra Pradesh' },
    { id: 130, city: 'Warangal', state: 'Andhra Pradesh' },
    { id: 131, city: 'Yellandu', state: 'Andhra Pradesh' },
    { id: 132, city: 'Yemmiganur', state: 'Andhra Pradesh' },
    { id: 133, city: 'Yerraguntla', state: 'Andhra Pradesh' },
    { id: 134, city: 'Zahirabad', state: 'Andhra Pradesh' },
    { id: 135, city: 'Rajampet', state: 'Andhra Pradesh' },
    { id: 136, city: 'Along', state: 'Arunachal Pradesh' },
    { id: 137, city: 'Bomdila', state: 'Arunachal Pradesh' },
    { id: 138, city: 'Itanagar', state: 'Arunachal Pradesh' },
    { id: 139, city: 'Naharlagun', state: 'Arunachal Pradesh' },
    { id: 140, city: 'Pasighat', state: 'Arunachal Pradesh' },
    { id: 141, city: 'Abhayapuri', state: 'Assam' },
    { id: 142, city: 'Amguri', state: 'Assam' },
    { id: 143, city: 'Anandnagaar', state: 'Assam' },
    { id: 144, city: 'Barpeta', state: 'Assam' },
    { id: 145, city: 'Barpeta Road', state: 'Assam' },
    { id: 146, city: 'Bilasipara', state: 'Assam' },
    { id: 147, city: 'Bongaigaon', state: 'Assam' },
    { id: 148, city: 'Dhekiajuli', state: 'Assam' },
    { id: 149, city: 'Dhubri', state: 'Assam' },
    { id: 150, city: 'Dibrugarh', state: 'Assam' },
    { id: 151, city: 'Digboi', state: 'Assam' },
    { id: 152, city: 'Diphu', state: 'Assam' },
    { id: 153, city: 'Dispur', state: 'Assam' },
    { id: 154, city: 'Gauripur', state: 'Assam' },
    { id: 155, city: 'Goalpara', state: 'Assam' },
    { id: 156, city: 'Golaghat', state: 'Assam' },
    { id: 157, city: 'Guwahati', state: 'Assam' },
    { id: 158, city: 'Haflong', state: 'Assam' },
    { id: 159, city: 'Hailakandi', state: 'Assam' },
    { id: 160, city: 'Hojai', state: 'Assam' },
    { id: 161, city: 'Jorhat', state: 'Assam' },
    { id: 162, city: 'Karimganj', state: 'Assam' },
    { id: 163, city: 'Kokrajhar', state: 'Assam' },
    { id: 164, city: 'Lanka', state: 'Assam' },
    { id: 165, city: 'Lumding', state: 'Assam' },
    { id: 166, city: 'Mangaldoi', state: 'Assam' },
    { id: 167, city: 'Mankachar', state: 'Assam' },
    { id: 168, city: 'Margherita', state: 'Assam' },
    { id: 169, city: 'Mariani', state: 'Assam' },
    { id: 170, city: 'Marigaon', state: 'Assam' },
    { id: 171, city: 'Nagaon', state: 'Assam' },
    { id: 172, city: 'Nalbari', state: 'Assam' },
    { id: 173, city: 'North Lakhimpur', state: 'Assam' },
    { id: 174, city: 'Rangia', state: 'Assam' },
    { id: 175, city: 'Sibsagar', state: 'Assam' },
    { id: 176, city: 'Silapathar', state: 'Assam' },
    { id: 177, city: 'Silchar', state: 'Assam' },
    { id: 178, city: 'Tezpur', state: 'Assam' },
    { id: 179, city: 'Tinsukia', state: 'Assam' },
    { id: 180, city: 'Amarpur', state: 'Bihar' },
    { id: 181, city: 'Araria', state: 'Bihar' },
    { id: 182, city: 'Areraj', state: 'Bihar' },
    { id: 183, city: 'Arrah', state: 'Bihar' },
    { id: 184, city: 'Asarganj', state: 'Bihar' },
    { id: 185, city: 'Aurangabad', state: 'Bihar' },
    { id: 186, city: 'Bagaha', state: 'Bihar' },
    { id: 187, city: 'Bahadurganj', state: 'Bihar' },
    { id: 188, city: 'Bairgania', state: 'Bihar' },
    { id: 189, city: 'Bakhtiarpur', state: 'Bihar' },
    { id: 190, city: 'Banka', state: 'Bihar' },
    { id: 191, city: 'Banmankhi Bazar', state: 'Bihar' },
    { id: 192, city: 'Barahiya', state: 'Bihar' },
    { id: 193, city: 'Barauli', state: 'Bihar' },
    { id: 194, city: 'Barbigha', state: 'Bihar' },
    { id: 195, city: 'Barh', state: 'Bihar' },
    { id: 196, city: 'Begusarai', state: 'Bihar' },
    { id: 197, city: 'Behea', state: 'Bihar' },
    { id: 198, city: 'Bettiah', state: 'Bihar' },
    { id: 199, city: 'Bhabua', state: 'Bihar' },
    { id: 200, city: 'Bhagalpur', state: 'Bihar' },
    { id: 201, city: 'Bihar Sharif', state: 'Bihar' },
    { id: 202, city: 'Bikramganj', state: 'Bihar' },
    { id: 203, city: 'Bodh Gaya', state: 'Bihar' },
    { id: 204, city: 'Buxar', state: 'Bihar' },
    { id: 205, city: 'Chandan Bara', state: 'Bihar' },
    { id: 206, city: 'Chanpatia', state: 'Bihar' },
    { id: 207, city: 'Chhapra', state: 'Bihar' },
    { id: 208, city: 'Colgong', state: 'Bihar' },
    { id: 209, city: 'Dalsinghsarai', state: 'Bihar' },
    { id: 210, city: 'Darbhanga', state: 'Bihar' },
    { id: 211, city: 'Daudnagar', state: 'Bihar' },
    { id: 212, city: 'Dehri-on-Sone', state: 'Bihar' },
    { id: 213, city: 'Dhaka', state: 'Bihar' },
    { id: 214, city: 'Dighwara', state: 'Bihar' },
    { id: 215, city: 'Dumraon', state: 'Bihar' },
    { id: 216, city: 'Fatwah', state: 'Bihar' },
    { id: 217, city: 'Forbesganj', state: 'Bihar' },
    { id: 218, city: 'Gaya', state: 'Bihar' },
    { id: 219, city: 'Gogri Jamalpur', state: 'Bihar' },
    { id: 220, city: 'Gopalganj', state: 'Bihar' },
    { id: 221, city: 'Hajipur', state: 'Bihar' },
    { id: 222, city: 'Hilsa', state: 'Bihar' },
    { id: 223, city: 'Hisua', state: 'Bihar' },
    { id: 224, city: 'Islampur', state: 'Bihar' },
    { id: 225, city: 'Jagdispur', state: 'Bihar' },
    { id: 226, city: 'Jamalpur', state: 'Bihar' },
    { id: 227, city: 'Jamui', state: 'Bihar' },
    { id: 228, city: 'Jehanabad', state: 'Bihar' },
    { id: 229, city: 'Jhajha', state: 'Bihar' },
    { id: 230, city: 'Jhanjharpur', state: 'Bihar' },
    { id: 231, city: 'Jogabani', state: 'Bihar' },
    { id: 232, city: 'Kanti', state: 'Bihar' },
    { id: 233, city: 'Katihar', state: 'Bihar' },
    { id: 234, city: 'Khagaria', state: 'Bihar' },
    { id: 235, city: 'Kharagpur', state: 'Bihar' },
    { id: 236, city: 'Kishanganj', state: 'Bihar' },
    { id: 237, city: 'Lakhisarai', state: 'Bihar' },
    { id: 238, city: 'Lalganj', state: 'Bihar' },
    { id: 239, city: 'Madhepura', state: 'Bihar' },
    { id: 240, city: 'Madhubani', state: 'Bihar' },
    { id: 241, city: 'Maharajganj', state: 'Bihar' },
    { id: 242, city: 'Mahnar Bazar', state: 'Bihar' },
    { id: 243, city: 'Makhdumpur', state: 'Bihar' },
    { id: 244, city: 'Maner', state: 'Bihar' },
    { id: 245, city: 'Manihari', state: 'Bihar' },
    { id: 246, city: 'Marhaura', state: 'Bihar' },
    { id: 247, city: 'Masaurhi', state: 'Bihar' },
    { id: 248, city: 'Mirganj', state: 'Bihar' },
    { id: 249, city: 'Mokameh', state: 'Bihar' },
    { id: 250, city: 'Motihari', state: 'Bihar' },
    { id: 251, city: 'Motipur', state: 'Bihar' },
    { id: 252, city: 'Munger', state: 'Bihar' },
    { id: 253, city: 'Murliganj', state: 'Bihar' },
    { id: 254, city: 'Muzaffarpur', state: 'Bihar' },
    { id: 255, city: 'Narkatiaganj', state: 'Bihar' },
    { id: 256, city: 'Naugachhia', state: 'Bihar' },
    { id: 257, city: 'Nawada', state: 'Bihar' },
    { id: 258, city: 'Nokha', state: 'Bihar' },
    { id: 259, city: 'Patna', state: 'Bihar' },
    { id: 260, city: 'Piro', state: 'Bihar' },
    { id: 261, city: 'Purnia', state: 'Bihar' },
    { id: 262, city: 'Rafiganj', state: 'Bihar' },
    { id: 263, city: 'Rajgir', state: 'Bihar' },
    { id: 264, city: 'Ramnagar', state: 'Bihar' },
    { id: 265, city: 'Raxaul Bazar', state: 'Bihar' },
    { id: 266, city: 'Revelganj', state: 'Bihar' },
    { id: 267, city: 'Rosera', state: 'Bihar' },
    { id: 268, city: 'Saharsa', state: 'Bihar' },
    { id: 269, city: 'Samastipur', state: 'Bihar' },
    { id: 270, city: 'Sasaram', state: 'Bihar' },
    { id: 271, city: 'Sheikhpura', state: 'Bihar' },
    { id: 272, city: 'Sheohar', state: 'Bihar' },
    { id: 273, city: 'Sherghati', state: 'Bihar' },
    { id: 274, city: 'Silao', state: 'Bihar' },
    { id: 275, city: 'Sitamarhi', state: 'Bihar' },
    { id: 276, city: 'Siwan', state: 'Bihar' },
    { id: 277, city: 'Sonepur', state: 'Bihar' },
    { id: 278, city: 'Sugauli', state: 'Bihar' },
    { id: 279, city: 'Sultanganj', state: 'Bihar' },
    { id: 280, city: 'Supaul', state: 'Bihar' },
    { id: 281, city: 'Warisaliganj', state: 'Bihar' },
    { id: 282, city: 'Ahiwara', state: 'Chhattisgarh' },
    { id: 283, city: 'Akaltara', state: 'Chhattisgarh' },
    { id: 284, city: 'Ambagarh Chowki', state: 'Chhattisgarh' },
    { id: 285, city: 'Ambikapur', state: 'Chhattisgarh' },
    { id: 286, city: 'Arang', state: 'Chhattisgarh' },
    { id: 287, city: 'Bade Bacheli', state: 'Chhattisgarh' },
    { id: 288, city: 'Balod', state: 'Chhattisgarh' },
    { id: 289, city: 'Baloda Bazar', state: 'Chhattisgarh' },
    { id: 290, city: 'Bemetra', state: 'Chhattisgarh' },
    { id: 291, city: 'Bhatapara', state: 'Chhattisgarh' },
    { id: 292, city: 'Bilaspur', state: 'Chhattisgarh' },
    { id: 293, city: 'Birgaon', state: 'Chhattisgarh' },
    { id: 294, city: 'Champa', state: 'Chhattisgarh' },
    { id: 295, city: 'Chirmiri', state: 'Chhattisgarh' },
    { id: 296, city: 'Dalli-Rajhara', state: 'Chhattisgarh' },
    { id: 297, city: 'Dhamtari', state: 'Chhattisgarh' },
    { id: 298, city: 'Dipka', state: 'Chhattisgarh' },
    { id: 299, city: 'Dongargarh', state: 'Chhattisgarh' },
    { id: 300, city: 'Durg-Bhilai Nagar', state: 'Chhattisgarh' },
    { id: 301, city: 'Gobranawapara', state: 'Chhattisgarh' },
    { id: 302, city: 'Jagdalpur', state: 'Chhattisgarh' },
    { id: 303, city: 'Janjgir', state: 'Chhattisgarh' },
    { id: 304, city: 'Jashpurnagar', state: 'Chhattisgarh' },
    { id: 305, city: 'Kanker', state: 'Chhattisgarh' },
    { id: 306, city: 'Kawardha', state: 'Chhattisgarh' },
    { id: 307, city: 'Kondagaon', state: 'Chhattisgarh' },
    { id: 308, city: 'Korba', state: 'Chhattisgarh' },
    { id: 309, city: 'Mahasamund', state: 'Chhattisgarh' },
    { id: 310, city: 'Mahendragarh', state: 'Chhattisgarh' },
    { id: 311, city: 'Mungeli', state: 'Chhattisgarh' },
    { id: 312, city: 'Naila Janjgir', state: 'Chhattisgarh' },
    { id: 313, city: 'Raigarh', state: 'Chhattisgarh' },
    { id: 314, city: 'Raipur', state: 'Chhattisgarh' },
    { id: 315, city: 'Rajnandgaon', state: 'Chhattisgarh' },
    { id: 316, city: 'Sakti', state: 'Chhattisgarh' },
    { id: 317, city: 'Tilda Newra', state: 'Chhattisgarh' },
    { id: 318, city: 'Amli', state: 'Dadra & Nagar Haveli' },
    { id: 319, city: 'Silvassa', state: 'Dadra & Nagar Haveli' },
    { id: 320, city: 'Daman and Diu', state: 'Daman & Diu' },
    { id: 321, city: 'Daman and Diu', state: 'Daman & Diu' },
    { id: 322, city: 'Asola', state: 'Delhi' },
    { id: 323, city: 'Delhi', state: 'Delhi' },
    { id: 324, city: 'Aldona', state: 'Goa' },
    { id: 325, city: 'Curchorem Cacora', state: 'Goa' },
    { id: 326, city: 'Madgaon', state: 'Goa' },
    { id: 327, city: 'Mapusa', state: 'Goa' },
    { id: 328, city: 'Margao', state: 'Goa' },
    { id: 329, city: 'Marmagao', state: 'Goa' },
    { id: 330, city: 'Panaji', state: 'Goa' },
    { id: 331, city: 'Ahmedabad', state: 'Gujarat' },
    { id: 332, city: 'Amreli', state: 'Gujarat' },
    { id: 333, city: 'Anand', state: 'Gujarat' },
    { id: 334, city: 'Ankleshwar', state: 'Gujarat' },
    { id: 335, city: 'Bharuch', state: 'Gujarat' },
    { id: 336, city: 'Bhavnagar', state: 'Gujarat' },
    { id: 337, city: 'Bhuj', state: 'Gujarat' },
    { id: 338, city: 'Cambay', state: 'Gujarat' },
    { id: 339, city: 'Dahod', state: 'Gujarat' },
    { id: 340, city: 'Deesa', state: 'Gujarat' },
    { id: 341, city: 'Dharampur', state: ' India' },
    { id: 342, city: 'Dholka', state: 'Gujarat' },
    { id: 343, city: 'Gandhinagar', state: 'Gujarat' },
    { id: 344, city: 'Godhra', state: 'Gujarat' },
    { id: 345, city: 'Himatnagar', state: 'Gujarat' },
    { id: 346, city: '', state: 'Gujarat' },
    { id: 347, city: 'Jamnagar', state: 'Gujarat' },
    { id: 348, city: 'Junagadh', state: 'Gujarat' },
    { id: 349, city: 'Kadi', state: 'Gujarat' },
    { id: 350, city: 'Kalavad', state: 'Gujarat' },
    { id: 351, city: 'Kalol', state: 'Gujarat' },
    { id: 352, city: 'Kapadvanj', state: 'Gujarat' },
    { id: 353, city: 'Karjan', state: 'Gujarat' },
    { id: 354, city: 'Keshod', state: 'Gujarat' },
    { id: 355, city: 'Khambhalia', state: 'Gujarat' },
    { id: 356, city: 'Khambhat', state: 'Gujarat' },
    { id: 357, city: 'Kheda', state: 'Gujarat' },
    { id: 358, city: 'Khedbrahma', state: 'Gujarat' },
    { id: 359, city: 'Kheralu', state: 'Gujarat' },
    { id: 360, city: 'Kodinar', state: 'Gujarat' },
    { id: 361, city: 'Lathi', state: 'Gujarat' },
    { id: 362, city: 'Limbdi', state: 'Gujarat' },
    { id: 363, city: 'Lunawada', state: 'Gujarat' },
    { id: 364, city: 'Mahesana', state: 'Gujarat' },
    { id: 365, city: 'Mahuva', state: 'Gujarat' },
    { id: 366, city: 'Manavadar', state: 'Gujarat' },
    { id: 367, city: 'Mandvi', state: 'Gujarat' },
    { id: 368, city: 'Mangrol', state: 'Gujarat' },
    { id: 369, city: 'Mansa', state: 'Gujarat' },
    { id: 370, city: 'Mehmedabad', state: 'Gujarat' },
    { id: 371, city: 'Modasa', state: 'Gujarat' },
    { id: 372, city: 'Morvi', state: 'Gujarat' },
    { id: 373, city: 'Nadiad', state: 'Gujarat' },
    { id: 374, city: 'Navsari', state: 'Gujarat' },
    { id: 375, city: 'Padra', state: 'Gujarat' },
    { id: 376, city: 'Palanpur', state: 'Gujarat' },
    { id: 377, city: 'Palitana', state: 'Gujarat' },
    { id: 378, city: 'Pardi', state: 'Gujarat' },
    { id: 379, city: 'Patan', state: 'Gujarat' },
    { id: 380, city: 'Petlad', state: 'Gujarat' },
    { id: 381, city: 'Porbandar', state: 'Gujarat' },
    { id: 382, city: 'Radhanpur', state: 'Gujarat' },
    { id: 383, city: 'Rajkot', state: 'Gujarat' },
    { id: 384, city: 'Rajpipla', state: 'Gujarat' },
    { id: 385, city: 'Rajula', state: 'Gujarat' },
    { id: 386, city: 'Ranavav', state: 'Gujarat' },
    { id: 387, city: 'Rapar', state: 'Gujarat' },
    { id: 388, city: 'Salaya', state: 'Gujarat' },
    { id: 389, city: 'Sanand', state: 'Gujarat' },
    { id: 390, city: 'Savarkundla', state: 'Gujarat' },
    { id: 391, city: 'Sur', state: 'Gujarat' },
    { id: 392, city: 'Sihor', state: 'Gujarat' },
    { id: 393, city: 'Songadh', state: 'Gujarat' },
    { id: 394, city: 'Surat', state: 'Gujarat' },
    { id: 395, city: 'Talaja', state: 'Gujarat' },
    { id: 396, city: 'Thangadh', state: 'Gujarat' },
    { id: 397, city: 'Tharad', state: 'Gujarat' },
    { id: 398, city: 'Umbergaon', state: 'Gujarat' },
    { id: 399, city: 'Umreth', state: 'Gujarat' },
    { id: 400, city: 'Una', state: 'Gujarat' },
    { id: 401, city: 'Unjha', state: 'Gujarat' },
    { id: 402, city: 'Upleta', state: 'Gujarat' },
    { id: 403, city: 'Vadnagar', state: 'Gujarat' },
    { id: 404, city: 'Vadodara', state: 'Gujarat' },
    { id: 405, city: 'Valsad', state: 'Gujarat' },
    { id: 406, city: 'Vapi', state: 'Gujarat' },
    { id: 407, city: 'Vapi', state: 'Gujarat' },
    { id: 408, city: 'Veraval', state: 'Gujarat' },
    { id: 409, city: 'Vijapur', state: 'Gujarat' },
    { id: 410, city: 'Viramgam', state: 'Gujarat' },
    { id: 411, city: 'Visnagar', state: 'Gujarat' },
    { id: 412, city: 'Vyara', state: 'Gujarat' },
    { id: 413, city: 'Wadhwan', state: 'Gujarat' },
    { id: 414, city: 'Wankaner', state: 'Gujarat' },
    { id: 415, city: 'Adalaj', state: 'Gujrat' },
    { id: 416, city: 'Adityana', state: 'Gujrat' },
    { id: 417, city: 'Alang', state: 'Gujrat' },
    { id: 418, city: 'Ambaji', state: 'Gujrat' },
    { id: 419, city: 'Ambaliyasan', state: 'Gujrat' },
    { id: 420, city: 'Andada', state: 'Gujrat' },
    { id: 421, city: 'Anjar', state: 'Gujrat' },
    { id: 422, city: 'Anklav', state: 'Gujrat' },
    { id: 423, city: 'Antaliya', state: 'Gujrat' },
    { id: 424, city: 'Arambhada', state: 'Gujrat' },
    { id: 425, city: 'Atul', state: 'Gujrat' },
    { id: 426, city: 'Ballabhgarh', state: 'Hariyana' },
    { id: 427, city: 'Ambala', state: 'Haryana' },
    { id: 428, city: 'Ambala', state: 'Haryana' },
    { id: 429, city: 'Asankhurd', state: 'Haryana' },
    { id: 430, city: 'Assandh', state: 'Haryana' },
    { id: 431, city: 'Ateli', state: 'Haryana' },
    { id: 432, city: 'Babiyal', state: 'Haryana' },
    { id: 433, city: 'Bahadurgarh', state: 'Haryana' },
    { id: 434, city: 'Barwala', state: 'Haryana' },
    { id: 435, city: 'Bhiwani', state: 'Haryana' },
    { id: 436, city: 'Charkhi Dadri', state: 'Haryana' },
    { id: 437, city: 'Cheeka', state: 'Haryana' },
    { id: 438, city: 'Ellenabad 2', state: 'Haryana' },
    { id: 439, city: 'Farad', state: 'Haryana' },
    { id: 440, city: 'Fatehabad', state: 'Haryana' },
    { id: 441, city: 'Ganaur', state: 'Haryana' },
    { id: 442, city: 'Gharaunda', state: 'Haryana' },
    { id: 443, city: 'Gohana', state: 'Haryana' },
    { id: 444, city: 'Gurgaon', state: 'Haryana' },
    { id: 445, city: 'Haibat(Yamuna Nagar)', state: 'Haryana' },
    { id: 446, city: 'Hansi', state: 'Haryana' },
    { id: 447, city: 'Hisar', state: 'Haryana' },
    { id: 448, city: 'Hodal', state: 'Haryana' },
    { id: 449, city: 'Jhajjar', state: 'Haryana' },
    { id: 450, city: 'Jind', state: 'Haryana' },
    { id: 451, city: 'Kaithal', state: 'Haryana' },
    { id: 452, city: 'Kalan Wali', state: 'Haryana' },
    { id: 453, city: 'Kalka', state: 'Haryana' },
    { id: 454, city: 'Karnal', state: 'Haryana' },
    { id: 455, city: 'Ladwa', state: 'Haryana' },
    { id: 456, city: 'Mahendragarh', state: 'Haryana' },
    { id: 457, city: 'Mandi Dabwali', state: 'Haryana' },
    { id: 458, city: 'Narnaul', state: 'Haryana' },
    { id: 459, city: 'Narwana', state: 'Haryana' },
    { id: 460, city: 'Palwal', state: 'Haryana' },
    { id: 461, city: 'Panchkula', state: 'Haryana' },
    { id: 462, city: 'Panipat', state: 'Haryana' },
    { id: 463, city: 'Pehowa', state: 'Haryana' },
    { id: 464, city: 'Pinjore', state: 'Haryana' },
    { id: 465, city: 'Rania', state: 'Haryana' },
    { id: 466, city: 'Ratia', state: 'Haryana' },
    { id: 467, city: 'Rewari', state: 'Haryana' },
    { id: 468, city: 'Rohtak', state: 'Haryana' },
    { id: 469, city: 'Saf', state: 'Haryana' },
    { id: 470, city: 'Samalkha', state: 'Haryana' },
    { id: 471, city: 'Shahbad', state: 'Haryana' },
    { id: 472, city: 'Sirsa', state: 'Haryana' },
    { id: 473, city: 'Sohna', state: 'Haryana' },
    { id: 474, city: 'Sonipat', state: 'Haryana' },
    { id: 475, city: 'Taraori', state: 'Haryana' },
    { id: 476, city: 'Thanesar', state: 'Haryana' },
    { id: 477, city: 'Tohana', state: 'Haryana' },
    { id: 478, city: 'Yamunanagar', state: 'Haryana' },
    { id: 479, city: 'Arki', state: 'Himachal Pradesh' },
    { id: 480, city: 'Baddi', state: 'Himachal Pradesh' },
    { id: 481, city: 'Bilaspur', state: 'Himachal Pradesh' },
    { id: 482, city: 'Chamba', state: 'Himachal Pradesh' },
    { id: 483, city: 'Dalhousie', state: 'Himachal Pradesh' },
    { id: 484, city: 'Dharamsala', state: 'Himachal Pradesh' },
    { id: 485, city: 'Hamirpur', state: 'Himachal Pradesh' },
    { id: 486, city: 'Mandi', state: 'Himachal Pradesh' },
    { id: 487, city: 'Nahan', state: 'Himachal Pradesh' },
    { id: 488, city: 'Shimla', state: 'Himachal Pradesh' },
    { id: 489, city: 'Solan', state: 'Himachal Pradesh' },
    { id: 490, city: 'Sundarnagar', state: 'Himachal Pradesh' },
    { id: 491, city: 'Jammu', state: 'Jammu & Kashmir' },
    { id: 492, city: 'Achabbal', state: 'Jammu & Kashmir' },
    { id: 493, city: 'Akhnoor', state: 'Jammu & Kashmir' },
    { id: 494, city: 'Anantnag', state: 'Jammu & Kashmir' },
    { id: 495, city: 'Arnia', state: 'Jammu & Kashmir' },
    { id: 496, city: 'Awantipora', state: 'Jammu & Kashmir' },
    { id: 497, city: 'Bandipore', state: 'Jammu & Kashmir' },
    { id: 498, city: 'Baramula', state: 'Jammu & Kashmir' },
    { id: 499, city: 'Kathua', state: 'Jammu & Kashmir' },
    { id: 500, city: 'Leh', state: 'Jammu & Kashmir' },
    { id: 501, city: 'Punch', state: 'Jammu & Kashmir' },
    { id: 502, city: 'Rajauri', state: 'Jammu & Kashmir' },
    { id: 503, city: 'Sopore', state: 'Jammu & Kashmir' },
    { id: 504, city: 'Srinagar', state: 'Jammu & Kashmir' },
    { id: 505, city: 'Udhampur', state: 'Jammu & Kashmir' },
    { id: 506, city: 'Amlabad', state: 'Jharkhand' },
    { id: 507, city: 'Ara', state: 'Jharkhand' },
    { id: 508, city: 'Barughutu', state: 'Jharkhand' },
    { id: 509, city: 'Bokaro Steel state', state: 'Jharkhand' },
    { id: 510, city: 'Chaibasa', state: 'Jharkhand' },
    { id: 511, city: 'Chakradharpur', state: 'Jharkhand' },
    { id: 512, city: 'Chandrapura', state: 'Jharkhand' },
    { id: 513, city: 'Chatra', state: 'Jharkhand' },
    { id: 514, city: 'Chirkunda', state: 'Jharkhand' },
    { id: 515, city: 'Churi', state: 'Jharkhand' },
    { id: 516, city: 'Daltonganj', state: 'Jharkhand' },
    { id: 517, city: 'Deoghar', state: 'Jharkhand' },
    { id: 518, city: 'Dhanbad', state: 'Jharkhand' },
    { id: 519, city: 'Dumka', state: 'Jharkhand' },
    { id: 520, city: 'Garhwa', state: 'Jharkhand' },
    { id: 521, city: 'Ghatshila', state: 'Jharkhand' },
    { id: 522, city: 'Gir', state: 'Jharkhand' },
    { id: 523, city: 'Godda', state: 'Jharkhand' },
    { id: 524, city: 'Gomoh', state: 'Jharkhand' },
    { id: 525, city: 'Gumia', state: 'Jharkhand' },
    { id: 526, city: 'Gumla', state: 'Jharkhand' },
    { id: 527, city: 'Hazaribag', state: 'Jharkhand' },
    { id: 528, city: 'Hussainabad', state: 'Jharkhand' },
    { id: 529, city: 'Jamshedpur', state: 'Jharkhand' },
    { id: 530, city: 'Jamtara', state: 'Jharkhand' },
    { id: 531, city: 'Jhumri Tilaiya', state: 'Jharkhand' },
    { id: 532, city: 'Khunti', state: 'Jharkhand' },
    { id: 533, city: 'Lohardaga', state: 'Jharkhand' },
    { id: 534, city: 'Madhupur', state: 'Jharkhand' },
    { id: 535, city: 'Mihijam', state: 'Jharkhand' },
    { id: 536, city: 'Musabani', state: 'Jharkhand' },
    { id: 537, city: 'Pakaur', state: 'Jharkhand' },
    { id: 538, city: 'Patratu', state: 'Jharkhand' },
    { id: 539, city: 'Phusro', state: 'Jharkhand' },
    { id: 540, city: 'Ramngarh', state: 'Jharkhand' },
    { id: 541, city: 'Ranchi', state: 'Jharkhand' },
    { id: 542, city: 'Sahibganj', state: 'Jharkhand' },
    { id: 543, city: 'Saunda', state: 'Jharkhand' },
    { id: 544, city: 'Simdega', state: 'Jharkhand' },
    { id: 545, city: 'Tenu Dam-cum- Kathhara', state: 'Jharkhand' },
    { id: 546, city: 'Arasikere', state: 'Karnataka' },
    { id: 547, city: 'Bangalore', state: 'Karnataka' },
    { id: 548, city: 'Belgaum', state: 'Karnataka' },
    { id: 549, city: 'Bellary', state: 'Karnataka' },
    { id: 550, city: 'Chamrajnagar', state: 'Karnataka' },
    { id: 551, city: 'Chikkaballapur', state: 'Karnataka' },
    { id: 552, city: 'Chintamani', state: 'Karnataka' },
    { id: 553, city: 'Chitradurga', state: 'Karnataka' },
    { id: 554, city: 'Gulbarga', state: 'Karnataka' },
    { id: 555, city: 'Gundlupet', state: 'Karnataka' },
    { id: 556, city: 'Hassan', state: 'Karnataka' },
    { id: 557, city: 'Hospet', state: 'Karnataka' },
    { id: 558, city: 'Hubli', state: 'Karnataka' },
    { id: 559, city: 'Karkala', state: 'Karnataka' },
    { id: 560, city: 'Karwar', state: 'Karnataka' },
    { id: 561, city: 'Kolar', state: 'Karnataka' },
    { id: 562, city: 'Kota', state: 'Karnataka' },
    { id: 563, city: 'Lakshmeshwar', state: 'Karnataka' },
    { id: 564, city: 'Lingsugur', state: 'Karnataka' },
    { id: 565, city: 'Maddur', state: 'Karnataka' },
    { id: 566, city: 'Madhugiri', state: 'Karnataka' },
    { id: 567, city: 'Madikeri', state: 'Karnataka' },
    { id: 568, city: 'Magadi', state: 'Karnataka' },
    { id: 569, city: 'Mahalingpur', state: 'Karnataka' },
    { id: 570, city: 'Malavalli', state: 'Karnataka' },
    { id: 571, city: 'Malur', state: 'Karnataka' },
    { id: 572, city: 'Mandya', state: 'Karnataka' },
    { id: 573, city: 'Mangalore', state: 'Karnataka' },
    { id: 574, city: 'Manvi', state: 'Karnataka' },
    { id: 575, city: 'Mudalgi', state: 'Karnataka' },
    { id: 576, city: 'Mudb', state: 'Karnataka' },
    { id: 577, city: 'Muddebihal', state: 'Karnataka' },
    { id: 578, city: 'Mudhol', state: 'Karnataka' },
    { id: 579, city: 'Mulbagal', state: 'Karnataka' },
    { id: 580, city: 'Mundargi', state: 'Karnataka' },
    { id: 581, city: 'Mysore', state: 'Karnataka' },
    { id: 582, city: 'Nanjangud', state: 'Karnataka' },
    { id: 583, city: 'Pavagada', state: 'Karnataka' },
    { id: 584, city: 'Puttur', state: 'Karnataka' },
    { id: 585, city: 'Rabkavi Banhatti', state: 'Karnataka' },
    { id: 586, city: 'Raichur', state: 'Karnataka' },
    { id: 587, city: 'Ramanagaram', state: 'Karnataka' },
    { id: 588, city: 'Ramdurg', state: 'Karnataka' },
    { id: 589, city: 'Ranibennur', state: 'Karnataka' },
    { id: 590, city: 'Robertson Pet', state: 'Karnataka' },
    { id: 591, city: 'Ron', state: 'Karnataka' },
    { id: 592, city: 'Sadalgi', state: 'Karnataka' },
    { id: 593, city: 'Sagar', state: 'Karnataka' },
    { id: 594, city: 'Sakleshpur', state: 'Karnataka' },
    { id: 595, city: 'Sandur', state: 'Karnataka' },
    { id: 596, city: 'Sankeshwar', state: 'Karnataka' },
    { id: 597, city: 'Saundatti-Yellamma', state: 'Karnataka' },
    { id: 598, city: 'Savanur', state: 'Karnataka' },
    { id: 599, city: 'Sedam', state: 'Karnataka' },
    { id: 600, city: 'Shahabad', state: 'Karnataka' },
    { id: 601, city: 'Shahpur', state: 'Karnataka' },
    { id: 602, city: 'Shiggaon', state: 'Karnataka' },
    { id: 603, city: 'Shikapur', state: 'Karnataka' },
    { id: 604, city: 'Shimoga', state: 'Karnataka' },
    { id: 605, city: 'Shorapur', state: 'Karnataka' },
    { id: 606, city: 'Shrirangapattana', state: 'Karnataka' },
    { id: 607, city: 'Sghatta', state: 'Karnataka' },
    { id: 608, city: 'Sindgi', state: 'Karnataka' },
    { id: 609, city: 'Sindhnur', state: 'Karnataka' },
    { id: 610, city: 'Sira', state: 'Karnataka' },
    { id: 611, city: 'Sirsi', state: 'Karnataka' },
    { id: 612, city: 'Siruguppa', state: 'Karnataka' },
    { id: 613, city: 'Srinivaspur', state: 'Karnataka' },
    { id: 614, city: 'Talikota', state: 'Karnataka' },
    { id: 615, city: 'Tarikere', state: 'Karnataka' },
    { id: 616, city: 'Tekkalakota', state: 'Karnataka' },
    { id: 617, city: 'Terdal', state: 'Karnataka' },
    { id: 618, city: 'Tiptur', state: 'Karnataka' },
    { id: 619, city: 'Tumkur', state: 'Karnataka' },
    { id: 620, city: 'Udupi', state: 'Karnataka' },
    { id: 621, city: 'Vijayapura', state: 'Karnataka' },
    { id: 622, city: 'Wadi', state: 'Karnataka' },
    { id: 623, city: 'Yadgir', state: 'Karnataka' },
    { id: 624, city: 'Adoor', state: 'Kerala' },
    { id: 625, city: 'Akathiyoor', state: 'Kerala' },
    { id: 626, city: 'Alappuzha', state: 'Kerala' },
    { id: 627, city: 'Ancharakandy', state: 'Kerala' },
    { id: 628, city: 'Aroor', state: 'Kerala' },
    { id: 629, city: 'Ashtamichira', state: 'Kerala' },
    { id: 630, city: 'Attingal', state: 'Kerala' },
    { id: 631, city: 'Avinissery', state: 'Kerala' },
    { id: 632, city: 'Chalakudy', state: 'Kerala' },
    { id: 633, city: 'Changanassery', state: 'Kerala' },
    { id: 634, city: 'Chendamangalam', state: 'Kerala' },
    { id: 635, city: 'Chengannur', state: 'Kerala' },
    { id: 636, city: 'Cherthala', state: 'Kerala' },
    { id: 637, city: 'Cheruthazham', state: 'Kerala' },
    { id: 638, city: 'Chittur-Thathamangalam', state: 'Kerala' },
    { id: 639, city: 'Chockli', state: 'Kerala' },
    { id: 640, city: 'Erattupetta', state: 'Kerala' },
    { id: 641, city: 'Guruvayoor', state: 'Kerala' },
    { id: 642, city: 'Irinjalakuda', state: 'Kerala' },
    { id: 643, city: 'Kadirur', state: 'Kerala' },
    { id: 644, city: 'Kalliasseri', state: 'Kerala' },
    { id: 645, city: 'Kalpetta', state: 'Kerala' },
    { id: 646, city: 'Kanhangad', state: 'Kerala' },
    { id: 647, city: 'Kanjikkuzhi', state: 'Kerala' },
    { id: 648, city: 'Kannur', state: 'Kerala' },
    { id: 649, city: 'Kasaragod', state: 'Kerala' },
    { id: 650, city: 'Kayamkulam', state: 'Kerala' },
    { id: 651, city: 'Kochi', state: 'Kerala' },
    { id: 652, city: 'Kodungallur', state: 'Kerala' },
    { id: 653, city: 'Kollam', state: 'Kerala' },
    { id: 654, city: 'Koothuparamba', state: 'Kerala' },
    { id: 655, city: 'Kothamangalam', state: 'Kerala' },
    { id: 656, city: 'Kottayam', state: 'Kerala' },
    { id: 657, city: 'Kozhikode', state: 'Kerala' },
    { id: 658, city: 'Kunnamkulam', state: 'Kerala' },
    { id: 659, city: 'Malappuram', state: 'Kerala' },
    { id: 660, city: 'Mattannur', state: 'Kerala' },
    { id: 661, city: 'Mavelikkara', state: 'Kerala' },
    { id: 662, city: 'Mavoor', state: 'Kerala' },
    { id: 663, city: 'Muvattupuzha', state: 'Kerala' },
    { id: 664, city: 'Nedumangad', state: 'Kerala' },
    { id: 665, city: 'Neyyattinkara', state: 'Kerala' },
    { id: 666, city: 'Ottappalam', state: 'Kerala' },
    { id: 667, city: 'Palai', state: 'Kerala' },
    { id: 668, city: 'Palakkad', state: 'Kerala' },
    { id: 669, city: 'Panniyannur', state: 'Kerala' },
    { id: 670, city: 'Pappinisseri', state: 'Kerala' },
    { id: 671, city: 'Paravoor', state: 'Kerala' },
    { id: 672, city: 'Pathanamthitta', state: 'Kerala' },
    { id: 673, city: 'Payyannur', state: 'Kerala' },
    { id: 674, city: 'Peringathur', state: 'Kerala' },
    { id: 675, city: 'Perinthalmanna', state: 'Kerala' },
    { id: 676, city: 'Perumbavoor', state: 'Kerala' },
    { id: 677, city: 'Ponnani', state: 'Kerala' },
    { id: 678, city: 'Punalur', state: 'Kerala' },
    { id: 679, city: 'Quilandy', state: 'Kerala' },
    { id: 680, city: 'Shoranur', state: 'Kerala' },
    { id: 681, city: 'Taliparamba', state: 'Kerala' },
    { id: 682, city: 'Thiruvalla', state: 'Kerala' },
    { id: 683, city: 'Thiruvananthapuram', state: 'Kerala' },
    { id: 684, city: 'Thodupuzha', state: 'Kerala' },
    { id: 685, city: 'Thrissur', state: 'Kerala' },
    { id: 686, city: 'Tirur', state: 'Kerala' },
    { id: 687, city: 'Vadakara', state: 'Kerala' },
    { id: 688, city: 'Vaikom', state: 'Kerala' },
    { id: 689, city: 'Varkala', state: 'Kerala' },
    { id: 690, city: 'Kavaratti', state: 'Lakshadweep' },
    { id: 691, city: 'Ashok Nagar', state: 'Madhya Pradesh' },
    { id: 692, city: 'Balaghat', state: 'Madhya Pradesh' },
    { id: 693, city: 'Betul', state: 'Madhya Pradesh' },
    { id: 694, city: 'Bhopal', state: 'Madhya Pradesh' },
    { id: 695, city: 'Burhanpur', state: 'Madhya Pradesh' },
    { id: 696, city: 'Chhatarpur', state: 'Madhya Pradesh' },
    { id: 697, city: 'Dabra', state: 'Madhya Pradesh' },
    { id: 698, city: 'Datia', state: 'Madhya Pradesh' },
    { id: 699, city: 'Dewas', state: 'Madhya Pradesh' },
    { id: 700, city: 'Dhar', state: 'Madhya Pradesh' },
    { id: 701, city: 'Fatehabad', state: 'Madhya Pradesh' },
    { id: 702, city: 'Gwalior', state: 'Madhya Pradesh' },
    { id: 703, city: 'Indore', state: 'Madhya Pradesh' },
    { id: 704, city: 'Itarsi', state: 'Madhya Pradesh' },
    { id: 705, city: 'Jabalpur', state: 'Madhya Pradesh' },
    { id: 706, city: 'Katni', state: 'Madhya Pradesh' },
    { id: 707, city: 'Kotma', state: 'Madhya Pradesh' },
    { id: 708, city: 'Lahar', state: 'Madhya Pradesh' },
    { id: 709, city: 'Lundi', state: 'Madhya Pradesh' },
    { id: 710, city: 'Maharajpur', state: 'Madhya Pradesh' },
    { id: 711, city: 'Mahr', state: 'Madhya Pradesh' },
    { id: 712, city: 'Maihar', state: 'Madhya Pradesh' },
    { id: 713, city: 'Malajkhand', state: 'Madhya Pradesh' },
    { id: 714, city: 'Manasa', state: 'Madhya Pradesh' },
    { id: 715, city: 'Manawar', state: 'Madhya Pradesh' },
    { id: 716, city: 'Mandp', state: 'Madhya Pradesh' },
    { id: 717, city: 'Mandla', state: 'Madhya Pradesh' },
    { id: 718, city: 'Mandsaur', state: 'Madhya Pradesh' },
    { id: 719, city: 'Mauganj', state: 'Madhya Pradesh' },
    { id: 720, city: 'Mhow Cantonment', state: 'Madhya Pradesh' },
    { id: 721, city: 'Mhowgaon', state: 'Madhya Pradesh' },
    { id: 722, city: 'Morena', state: 'Madhya Pradesh' },
    { id: 723, city: 'Multai', state: 'Madhya Pradesh' },
    { id: 724, city: 'Murwara', state: 'Madhya Pradesh' },
    { id: 725, city: 'Nagda', state: 'Madhya Pradesh' },
    { id: 726, city: 'Nainpur', state: 'Madhya Pradesh' },
    { id: 727, city: 'Narsinghgarh', state: 'Madhya Pradesh' },
    { id: 728, city: 'Narsinghgarh', state: 'Madhya Pradesh' },
    { id: 729, city: 'Neemuch', state: 'Madhya Pradesh' },
    { id: 730, city: 'Nepanagar', state: 'Madhya Pradesh' },
    { id: 731, city: 'Niwari', state: 'Madhya Pradesh' },
    { id: 732, city: 'Nowgong', state: 'Madhya Pradesh' },
    { id: 733, city: 'Nowrozabad', state: 'Madhya Pradesh' },
    { id: 734, city: 'Pachore', state: 'Madhya Pradesh' },
    { id: 735, city: 'Pali', state: 'Madhya Pradesh' },
    { id: 736, city: 'Panagar', state: 'Madhya Pradesh' },
    { id: 737, city: 'Pandhurna', state: 'Madhya Pradesh' },
    { id: 738, city: 'Panna', state: 'Madhya Pradesh' },
    { id: 739, city: 'Pasan', state: 'Madhya Pradesh' },
    { id: 740, city: 'Pipariya', state: 'Madhya Pradesh' },
    { id: 741, city: 'Pithampur', state: 'Madhya Pradesh' },
    { id: 742, city: 'Porsa', state: 'Madhya Pradesh' },
    { id: 743, city: 'Prithvipur', state: 'Madhya Pradesh' },
    { id: 744, city: 'Raghogarh-Vijaypur', state: 'Madhya Pradesh' },
    { id: 745, city: 'Rahatgarh', state: 'Madhya Pradesh' },
    { id: 746, city: 'Raisen', state: 'Madhya Pradesh' },
    { id: 747, city: 'Rajgarh', state: 'Madhya Pradesh' },
    { id: 748, city: 'Ratlam', state: 'Madhya Pradesh' },
    { id: 749, city: 'Rau', state: 'Madhya Pradesh' },
    { id: 750, city: 'Rehli', state: 'Madhya Pradesh' },
    { id: 751, city: 'Rewa', state: 'Madhya Pradesh' },
    { id: 752, city: 'Sabalgarh', state: 'Madhya Pradesh' },
    { id: 753, city: 'Sagar', state: 'Madhya Pradesh' },
    { id: 754, city: 'Sanawad', state: 'Madhya Pradesh' },
    { id: 755, city: 'Sarangpur', state: 'Madhya Pradesh' },
    { id: 756, city: 'Sarni', state: 'Madhya Pradesh' },
    { id: 757, city: 'Satna', state: 'Madhya Pradesh' },
    { id: 758, city: 'Sausar', state: 'Madhya Pradesh' },
    { id: 759, city: 'Sehore', state: 'Madhya Pradesh' },
    { id: 760, city: 'Sendhwa', state: 'Madhya Pradesh' },
    { id: 761, city: 'Seoni', state: 'Madhya Pradesh' },
    { id: 762, city: 'Seoni-Malwa', state: 'Madhya Pradesh' },
    { id: 763, city: 'Shahdol', state: 'Madhya Pradesh' },
    { id: 764, city: 'Shajapur', state: 'Madhya Pradesh' },
    { id: 765, city: 'Shamgarh', state: 'Madhya Pradesh' },
    { id: 766, city: 'Sheopur', state: 'Madhya Pradesh' },
    { id: 767, city: 'Shivpuri', state: 'Madhya Pradesh' },
    { id: 768, city: 'Shujalpur', state: 'Madhya Pradesh' },
    { id: 769, city: 'S', state: 'Madhya Pradesh' },
    { id: 770, city: 'Sihora', state: 'Madhya Pradesh' },
    { id: 771, city: 'Singrauli', state: 'Madhya Pradesh' },
    { id: 772, city: 'Sironj', state: 'Madhya Pradesh' },
    { id: 773, city: 'Sohagpur', state: 'Madhya Pradesh' },
    { id: 774, city: 'Tarana', state: 'Madhya Pradesh' },
    { id: 775, city: 'Tikamgarh', state: 'Madhya Pradesh' },
    { id: 776, city: 'Ujhani', state: 'Madhya Pradesh' },
    { id: 777, city: 'Ujjain', state: 'Madhya Pradesh' },
    { id: 778, city: 'Umaria', state: 'Madhya Pradesh' },
    { id: 779, city: 'Vha', state: 'Madhya Pradesh' },
    { id: 780, city: 'Wara Seoni', state: 'Madhya Pradesh' },
    { id: 781, city: 'Ahmednagar', state: 'Maharashtra' },
    { id: 782, city: 'Akola', state: 'Maharashtra' },
    { id: 783, city: 'Amravati', state: 'Maharashtra' },
    { id: 784, city: 'Aurangabad', state: 'Maharashtra' },
    { id: 785, city: 'Baramati', state: 'Maharashtra' },
    { id: 786, city: 'Chalisgaon', state: 'Maharashtra' },
    { id: 787, city: 'Chinchani', state: 'Maharashtra' },
    { id: 788, city: 'Devgarh', state: 'Maharashtra' },
    { id: 789, city: 'Dhule', state: 'Maharashtra' },
    { id: 790, city: 'Dombivli', state: 'Maharashtra' },
    { id: 791, city: 'Durgapur', state: 'Maharashtra' },
    { id: 792, city: 'Ichalkaranji', state: 'Maharashtra' },
    { id: 793, city: 'Jalna', state: 'Maharashtra' },
    { id: 794, city: 'Kalyan', state: 'Maharashtra' },
    { id: 795, city: 'Latur', state: 'Maharashtra' },
    { id: 796, city: 'Loha', state: 'Maharashtra' },
    { id: 797, city: 'Lonar', state: 'Maharashtra' },
    { id: 798, city: 'Lonavla', state: 'Maharashtra' },
    { id: 799, city: 'Mahad', state: 'Maharashtra' },
    { id: 800, city: 'Mahuli', state: 'Maharashtra' },
    { id: 801, city: 'Malegaon', state: 'Maharashtra' },
    { id: 802, city: 'Malkapur', state: 'Maharashtra' },
    { id: 803, city: 'Manchar', state: 'Maharashtra' },
    { id: 804, city: 'Mangalvedhe', state: 'Maharashtra' },
    { id: 805, city: 'Mangrulpir', state: 'Maharashtra' },
    { id: 806, city: 'Manjlegaon', state: 'Maharashtra' },
    { id: 807, city: 'Manmad', state: 'Maharashtra' },
    { id: 808, city: 'Manwath', state: 'Maharashtra' },
    { id: 809, city: 'Mehkar', state: 'Maharashtra' },
    { id: 810, city: 'Mhaswad', state: 'Maharashtra' },
    { id: 811, city: 'Miraj', state: 'Maharashtra' },
    { id: 812, city: 'Morshi', state: 'Maharashtra' },
    { id: 813, city: 'Mukhed', state: 'Maharashtra' },
    { id: 814, city: 'Mul', state: 'Maharashtra' },
    { id: 815, city: 'Mumbai', state: 'Maharashtra' },
    { id: 816, city: 'Murtijapur', state: 'Maharashtra' },
    { id: 817, city: 'Nagpur', state: 'Maharashtra' },
    { id: 818, city: 'Nalasopara', state: 'Maharashtra' },
    { id: 819, city: 'Nanded-Waghala', state: 'Maharashtra' },
    { id: 820, city: 'Nandgaon', state: 'Maharashtra' },
    { id: 821, city: 'Nandura', state: 'Maharashtra' },
    { id: 822, city: 'Nandurbar', state: 'Maharashtra' },
    { id: 823, city: 'Narkhed', state: 'Maharashtra' },
    { id: 824, city: 'Nashik', state: 'Maharashtra' },
    { id: 825, city: 'Navi Mumbai', state: 'Maharashtra' },
    { id: 826, city: 'Nawapur', state: 'Maharashtra' },
    { id: 827, city: 'Nilanga', state: 'Maharashtra' },
    { id: 828, city: 'Osmanabad', state: 'Maharashtra' },
    { id: 829, city: 'Ozar', state: 'Maharashtra' },
    { id: 830, city: 'Pachora', state: 'Maharashtra' },
    { id: 831, city: 'Paithan', state: 'Maharashtra' },
    { id: 832, city: 'Palghar', state: 'Maharashtra' },
    { id: 833, city: 'Pandharkaoda', state: 'Maharashtra' },
    { id: 834, city: 'Pandharpur', state: 'Maharashtra' },
    { id: 835, city: 'Panvel', state: 'Maharashtra' },
    { id: 836, city: 'Parbhani', state: 'Maharashtra' },
    { id: 837, city: 'Parli', state: 'Maharashtra' },
    { id: 838, city: 'Parola', state: 'Maharashtra' },
    { id: 839, city: 'Partur', state: 'Maharashtra' },
    { id: 840, city: 'Pathardi', state: 'Maharashtra' },
    { id: 841, city: 'Pathri', state: 'Maharashtra' },
    { id: 842, city: 'Patur', state: 'Maharashtra' },
    { id: 843, city: 'Pauni', state: 'Maharashtra' },
    { id: 844, city: 'Pen', state: 'Maharashtra' },
    { id: 845, city: 'Phaltan', state: 'Maharashtra' },
    { id: 846, city: 'Pulgaon', state: 'Maharashtra' },
    { id: 847, city: 'Pune', state: 'Maharashtra' },
    { id: 848, city: 'Purna', state: 'Maharashtra' },
    { id: 849, city: 'Pusad', state: 'Maharashtra' },
    { id: 850, city: 'Rahuri', state: 'Maharashtra' },
    { id: 851, city: 'Rajura', state: 'Maharashtra' },
    { id: 852, city: 'Ramtek', state: 'Maharashtra' },
    { id: 853, city: 'Ratnagiri', state: 'Maharashtra' },
    { id: 854, city: 'Raver', state: 'Maharashtra' },
    { id: 855, city: 'Risod', state: 'Maharashtra' },
    { id: 856, city: 'Sailu', state: 'Maharashtra' },
    { id: 857, city: 'Sangamner', state: 'Maharashtra' },
    { id: 858, city: 'Sangli', state: 'Maharashtra' },
    { id: 859, city: 'Sangole', state: 'Maharashtra' },
    { id: 860, city: 'Sasvad', state: 'Maharashtra' },
    { id: 861, city: 'Satana', state: 'Maharashtra' },
    { id: 862, city: 'Satara', state: 'Maharashtra' },
    { id: 863, city: 'Savner', state: 'Maharashtra' },
    { id: 864, city: 'Sawantwadi', state: 'Maharashtra' },
    { id: 865, city: 'Shahade', state: 'Maharashtra' },
    { id: 866, city: 'Shegaon', state: 'Maharashtra' },
    { id: 867, city: 'Shendurjana', state: 'Maharashtra' },
    { id: 868, city: 'Shirdi', state: 'Maharashtra' },
    { id: 869, city: 'Shirpur-Warwade', state: 'Maharashtra' },
    { id: 870, city: 'Shirur', state: 'Maharashtra' },
    { id: 871, city: 'Shrigonda', state: 'Maharashtra' },
    { id: 872, city: 'Shrirampur', state: 'Maharashtra' },
    { id: 873, city: 'Sillod', state: 'Maharashtra' },
    { id: 874, city: 'Sinnar', state: 'Maharashtra' },
    { id: 875, city: 'Solapur', state: 'Maharashtra' },
    { id: 876, city: 'Soyagaon', state: 'Maharashtra' },
    { id: 877, city: 'Talegaon Dabhade', state: 'Maharashtra' },
    { id: 878, city: 'Talode', state: 'Maharashtra' },
    { id: 879, city: 'Tasgaon', state: 'Maharashtra' },
    { id: 880, city: 'Tirora', state: 'Maharashtra' },
    { id: 881, city: 'Tuljapur', state: 'Maharashtra' },
    { id: 882, city: 'Tumsar', state: 'Maharashtra' },
    { id: 883, city: 'Uran', state: 'Maharashtra' },
    { id: 884, city: 'Uran Islampur', state: 'Maharashtra' },
    { id: 885, city: 'Wadgaon Road', state: 'Maharashtra' },
    { id: 886, city: 'Wai', state: 'Maharashtra' },
    { id: 887, city: 'Wani', state: 'Maharashtra' },
    { id: 888, city: 'Wardha', state: 'Maharashtra' },
    { id: 889, city: 'Warora', state: 'Maharashtra' },
    { id: 890, city: 'Warud', state: 'Maharashtra' },
    { id: 891, city: 'Washim', state: 'Maharashtra' },
    { id: 892, city: 'Yevla', state: 'Maharashtra' },
    { id: 893, city: 'Uchgaon', state: 'Maharashtra' },
    { id: 894, city: 'Udgir', state: 'Maharashtra' },
    { id: 895, city: 'Umarga', state: 'Maharastra' },
    { id: 896, city: 'Umarkhed', state: 'Maharastra' },
    { id: 897, city: 'Umred', state: 'Maharastra' },
    { id: 898, city: 'Vadgaon Kasba', state: 'Maharastra' },
    { id: 899, city: 'Vaijapur', state: 'Maharastra' },
    { id: 900, city: 'Vasai', state: 'Maharastra' },
    { id: 901, city: 'Virar', state: 'Maharastra' },
    { id: 902, city: 'Vita', state: 'Maharastra' },
    { id: 903, city: 'Yavatmal', state: 'Maharastra' },
    { id: 904, city: 'Yawal', state: 'Maharastra' },
    { id: 905, city: 'Imphal', state: 'Manipur' },
    { id: 906, city: 'Kakching', state: 'Manipur' },
    { id: 907, city: 'Lilong', state: 'Manipur' },
    { id: 908, city: 'Mayang Imphal', state: 'Manipur' },
    { id: 909, city: 'Thoubal', state: 'Manipur' },
    { id: 910, city: 'Jowai', state: 'Meghalaya' },
    { id: 911, city: 'Nongstoin', state: 'Meghalaya' },
    { id: 912, city: 'Shillong', state: 'Meghalaya' },
    { id: 913, city: 'Tura', state: 'Meghalaya' },
    { id: 914, city: 'Aizawl', state: 'Mizoram' },
    { id: 915, city: 'Champhai', state: 'Mizoram' },
    { id: 916, city: 'Lunglei', state: 'Mizoram' },
    { id: 917, city: 'Saiha', state: 'Mizoram' },
    { id: 918, city: 'Dimapur', state: 'Nagaland' },
    { id: 919, city: 'Kohima', state: 'Nagaland' },
    { id: 920, city: 'Mokokchung', state: 'Nagaland' },
    { id: 921, city: 'Tuensang', state: 'Nagaland' },
    { id: 922, city: 'Wokha', state: 'Nagaland' },
    { id: 923, city: 'Zunheboto', state: 'Nagaland' },
    { id: 924, city: 'Anandapur', state: 'Orissa' },
    { id: 925, city: 'Anugul', state: 'Orissa' },
    { id: 926, city: 'Asika', state: 'Orissa' },
    { id: 927, city: 'Balangir', state: 'Orissa' },
    { id: 928, city: 'Balasore', state: 'Orissa' },
    { id: 929, city: 'Baleshwar', state: 'Orissa' },
    { id: 930, city: 'Bamra', state: 'Orissa' },
    { id: 931, city: 'Barbil', state: 'Orissa' },
    { id: 932, city: 'Bargarh', state: 'Orissa' },
    { id: 933, city: 'Bargarh', state: 'Orissa' },
    { id: 934, city: 'Baripada', state: 'Orissa' },
    { id: 935, city: 'Basudebpur', state: 'Orissa' },
    { id: 936, city: 'Belpahar', state: 'Orissa' },
    { id: 937, city: 'Bhadrak', state: 'Orissa' },
    { id: 938, city: 'Bhawanipatna', state: 'Orissa' },
    { id: 939, city: 'Bhuban', state: 'Orissa' },
    { id: 940, city: 'Bhubaneswar', state: 'Orissa' },
    { id: 941, city: 'Biramitrapur', state: 'Orissa' },
    { id: 942, city: 'Brahmapur', state: 'Orissa' },
    { id: 943, city: 'Brajrajnagar', state: 'Orissa' },
    { id: 944, city: 'Byasanagar', state: 'Orissa' },
    { id: 945, city: 'Cuttack', state: 'Orissa' },
    { id: 946, city: 'Debagarh', state: 'Orissa' },
    { id: 947, city: 'Dhenkanal', state: 'Orissa' },
    { id: 948, city: 'Gunupur', state: 'Orissa' },
    { id: 949, city: 'Hinjilicut', state: 'Orissa' },
    { id: 950, city: 'Jagatsinghapur', state: 'Orissa' },
    { id: 951, city: 'Jajapur', state: 'Orissa' },
    { id: 952, city: 'Jaleswar', state: 'Orissa' },
    { id: 953, city: 'Jatani', state: 'Orissa' },
    { id: 954, city: 'Jeypur', state: 'Orissa' },
    { id: 955, city: 'Jharsuguda', state: 'Orissa' },
    { id: 956, city: 'Joda', state: 'Orissa' },
    { id: 957, city: 'Kantabanji', state: 'Orissa' },
    { id: 958, city: 'Karanjia', state: 'Orissa' },
    { id: 959, city: 'Kendrapara', state: 'Orissa' },
    { id: 960, city: 'Kendujhar', state: 'Orissa' },
    { id: 961, city: 'Khordha', state: 'Orissa' },
    { id: 962, city: 'Koraput', state: 'Orissa' },
    { id: 963, city: 'Malkangiri', state: 'Orissa' },
    { id: 964, city: 'Nabarangapur', state: 'Orissa' },
    { id: 965, city: 'Paradip', state: 'Orissa' },
    { id: 966, city: 'Parlakhemundi', state: 'Orissa' },
    { id: 967, city: 'Pattamundai', state: 'Orissa' },
    { id: 968, city: 'Phulabani', state: 'Orissa' },
    { id: 969, city: 'Puri', state: 'Orissa' },
    { id: 970, city: 'Rairangpur', state: 'Orissa' },
    { id: 971, city: 'Rajagangapur', state: 'Orissa' },
    { id: 972, city: 'Raurkela', state: 'Orissa' },
    { id: 973, city: 'Rayagada', state: 'Orissa' },
    { id: 974, city: 'Sambalpur', state: 'Orissa' },
    { id: 975, city: 'Soro', state: 'Orissa' },
    { id: 976, city: 'Sunabeda', state: 'Orissa' },
    { id: 977, city: 'Sundargarh', state: 'Orissa' },
    { id: 978, city: 'Talcher', state: 'Orissa' },
    { id: 979, city: 'Titlagarh', state: 'Orissa' },
    { id: 980, city: 'Umarkote', state: 'Orissa' },
    { id: 981, city: 'Karaikal', state: 'Pondicherry' },
    { id: 982, city: 'Mahe', state: 'Pondicherry' },
    { id: 983, city: 'Pondicherry', state: 'Pondicherry' },
    { id: 984, city: 'Yanam', state: 'Pondicherry' },
    { id: 985, city: 'Ahmedgarh', state: 'Punjab' },
    { id: 986, city: 'Amritsar', state: 'Punjab' },
    { id: 987, city: 'Barnala', state: 'Punjab' },
    { id: 988, city: 'Batala', state: 'Punjab' },
    { id: 989, city: 'Bathinda', state: 'Punjab' },
    { id: 990, city: 'Bhagha Purana', state: 'Punjab' },
    { id: 991, city: 'Budhlada', state: 'Punjab' },
    { id: 992, city: 'Chandigarh', state: 'Punjab' },
    { id: 993, city: 'Dasua', state: 'Punjab' },
    { id: 994, city: 'Dhuri', state: 'Punjab' },
    { id: 995, city: 'Dinanagar', state: 'Punjab' },
    { id: 996, city: 'Fart', state: 'Punjab' },
    { id: 997, city: 'Fazilka', state: 'Punjab' },
    { id: 998, city: 'Firozpur', state: 'Punjab' },
    { id: 999, city: 'Firozpur Cantt.', state: 'Punjab' },
    { id: 1000, city: 'Grbaha', state: 'Punjab' },
    { id: 1001, city: 'Gobindgarh', state: 'Punjab' },
    { id: 1002, city: 'Gurdaspur', state: 'Punjab' },
    { id: 1003, city: 'Hoshiarpur', state: 'Punjab' },
    { id: 1004, city: 'Jagraon', state: 'Punjab' },
    { id: 1005, city: 'Jaitu', state: 'Punjab' },
    { id: 1006, city: 'Jalalabad', state: 'Punjab' },
    { id: 1007, city: 'Jalandhar', state: 'Punjab' },
    { id: 1008, city: 'Jalandhar Cantt.', state: 'Punjab' },
    { id: 1009, city: 'Jandiala', state: 'Punjab' },
    { id: 1010, city: 'Kapurthala', state: 'Punjab' },
    { id: 1011, city: 'Karoran', state: 'Punjab' },
    { id: 1012, city: 'Kartarpur', state: 'Punjab' },
    { id: 1013, city: 'Khanna', state: 'Punjab' },
    { id: 1014, city: 'Kharar', state: 'Punjab' },
    { id: 1015, city: 'Kot Kapura', state: 'Punjab' },
    { id: 1016, city: 'Kurali', state: 'Punjab' },
    { id: 1017, city: 'Longowal', state: 'Punjab' },
    { id: 1018, city: 'Ludhiana', state: 'Punjab' },
    { id: 1019, city: 'Malerkotla', state: 'Punjab' },
    { id: 1020, city: 'Malout', state: 'Punjab' },
    { id: 1021, city: 'Mansa', state: 'Punjab' },
    { id: 1022, city: 'Maur', state: 'Punjab' },
    { id: 1023, city: 'Moga', state: 'Punjab' },
    { id: 1024, city: 'Mohali', state: 'Punjab' },
    { id: 1025, city: 'Morinda', state: 'Punjab' },
    { id: 1026, city: 'Mukerian', state: 'Punjab' },
    { id: 1027, city: 'Muktsar', state: 'Punjab' },
    { id: 1028, city: 'Nabha', state: 'Punjab' },
    { id: 1029, city: 'Nakodar', state: 'Punjab' },
    { id: 1030, city: 'Nangal', state: 'Punjab' },
    { id: 1031, city: 'Nawanshahr', state: 'Punjab' },
    { id: 1032, city: 'Pathankot', state: 'Punjab' },
    { id: 1033, city: 'Patiala', state: 'Punjab' },
    { id: 1034, city: 'Patran', state: 'Punjab' },
    { id: 1035, city: 'Patti', state: 'Punjab' },
    { id: 1036, city: 'Phagwara', state: 'Punjab' },
    { id: 1037, city: 'Phillaur', state: 'Punjab' },
    { id: 1038, city: 'Qadian', state: 'Punjab' },
    { id: 1039, city: 'Raikot', state: 'Punjab' },
    { id: 1040, city: 'Rajpura', state: 'Punjab' },
    { id: 1041, city: 'Rampura Phul', state: 'Punjab' },
    { id: 1042, city: 'Rupnagar', state: 'Punjab' },
    { id: 1043, city: 'Samana', state: 'Punjab' },
    { id: 1044, city: 'Sangrur', state: 'Punjab' },
    { id: 1045, city: 'Sirhind Fatehgarh Sahib', state: 'Punjab' },
    { id: 1046, city: 'Sujanpur', state: 'Punjab' },
    { id: 1047, city: 'Sunam', state: 'Punjab' },
    { id: 1048, city: 'Talwara', state: 'Punjab' },
    { id: 1049, city: 'Tarn Taran', state: 'Punjab' },
    { id: 1050, city: 'Urmar Tanda', state: 'Punjab' },
    { id: 1051, city: 'Zira', state: 'Punjab' },
    { id: 1052, city: 'Zirakpur', state: 'Punjab' },
    { id: 1053, city: 'Bali', state: 'Rajasthan' },
    { id: 1054, city: 'Banswara', state: 'Rajastan' },
    { id: 1055, city: 'Ajmer', state: 'Rajasthan' },
    { id: 1056, city: 'Alwar', state: 'Rajasthan' },
    { id: 1057, city: 'Bandikui', state: 'Rajasthan' },
    { id: 1058, city: 'Baran', state: 'Rajasthan' },
    { id: 1059, city: 'Barmer', state: 'Rajasthan' },
    { id: 1060, city: 'Bikaner', state: 'Rajasthan' },
    { id: 1061, city: 'Fatehpur', state: 'Rajasthan' },
    { id: 1062, city: 'Jaipur', state: 'Rajasthan' },
    { id: 1063, city: 'Jaisalmer', state: 'Rajasthan' },
    { id: 1064, city: 'Jodhpur', state: 'Rajasthan' },
    { id: 1065, city: 'Kota', state: 'Rajasthan' },
    { id: 1066, city: 'Lachhmangarh', state: 'Rajasthan' },
    { id: 1067, city: 'Ladnu', state: 'Rajasthan' },
    { id: 1068, city: 'Lakheri', state: 'Rajasthan' },
    { id: 1069, city: 'Lalsot', state: 'Rajasthan' },
    { id: 1070, city: 'Losal', state: 'Rajasthan' },
    { id: 1071, city: 'Makrana', state: 'Rajasthan' },
    { id: 1072, city: 'Malpura', state: 'Rajasthan' },
    { id: 1073, city: 'Mandalgarh', state: 'Rajasthan' },
    { id: 1074, city: 'Mandawa', state: 'Rajasthan' },
    { id: 1075, city: 'Mangrol', state: 'Rajasthan' },
    { id: 1076, city: 'Merta state', state: 'Rajasthan' },
    { id: 1077, city: 'Mount Abu', state: 'Rajasthan' },
    { id: 1078, city: 'Nadbai', state: 'Rajasthan' },
    { id: 1079, city: 'Nagar', state: 'Rajasthan' },
    { id: 1080, city: 'Nagaur', state: 'Rajasthan' },
    { id: 1081, city: 'Nargund', state: 'Rajasthan' },
    { id: 1082, city: 'Nasirabad', state: 'Rajasthan' },
    { id: 1083, city: 'Nathdwara', state: 'Rajasthan' },
    { id: 1084, city: 'Navalgund', state: 'Rajasthan' },
    { id: 1085, city: 'Nawalgarh', state: 'Rajasthan' },
    { id: 1086, city: 'Neem-Ka-Thana', state: 'Rajasthan' },
    { id: 1087, city: 'Nelamangala', state: 'Rajasthan' },
    { id: 1088, city: 'Nimbahera', state: 'Rajasthan' },
    { id: 1089, city: 'Nipani', state: 'Rajasthan' },
    { id: 1090, city: 'Niwai', state: 'Rajasthan' },
    { id: 1091, city: 'Nohar', state: 'Rajasthan' },
    { id: 1092, city: 'Nokha', state: 'Rajasthan' },
    { id: 1093, city: 'Pali', state: 'Rajasthan' },
    { id: 1094, city: 'Phalodi', state: 'Rajasthan' },
    { id: 1095, city: 'Phulera', state: 'Rajasthan' },
    { id: 1096, city: 'Pilani', state: 'Rajasthan' },
    { id: 1097, city: 'Pilibanga', state: 'Rajasthan' },
    { id: 1098, city: 'Pindwara', state: 'Rajasthan' },
    { id: 1099, city: 'Pipar', state: 'Rajasthan' },
    { id: 1100, city: 'Prantij', state: 'Rajasthan' },
    { id: 1101, city: 'Pratapgarh', state: 'Rajasthan' },
    { id: 1102, city: 'Raisinghnagar', state: 'Rajasthan' },
    { id: 1103, city: 'Rajakhera', state: 'Rajasthan' },
    { id: 1104, city: 'Rajaldesar', state: 'Rajasthan' },
    { id: 1105, city: 'Rajgarh (Alwar)', state: 'Rajasthan' },
    { id: 1106, city: 'Rajgarh (Churu', state: 'Rajasthan' },
    { id: 1107, city: 'Rajsamand', state: 'Rajasthan' },
    { id: 1108, city: 'Ramganj Mandi', state: 'Rajasthan' },
    { id: 1109, city: 'Ramngarh', state: 'Rajasthan' },
    { id: 1110, city: 'Ratangarh', state: 'Rajasthan' },
    { id: 1111, city: 'Rawatbhata', state: 'Rajasthan' },
    { id: 1112, city: 'Rawatsar', state: 'Rajasthan' },
    { id: 1113, city: 'Reengus', state: 'Rajasthan' },
    { id: 1114, city: 'Sadri', state: 'Rajasthan' },
    { id: 1115, city: 'Sadulshahar', state: 'Rajasthan' },
    { id: 1116, city: 'Sagwara', state: 'Rajasthan' },
    { id: 1117, city: 'Sambhar', state: 'Rajasthan' },
    { id: 1118, city: 'Sanchore', state: 'Rajasthan' },
    { id: 1119, city: 'Sangaria', state: 'Rajasthan' },
    { id: 1120, city: 'Sardarshahar', state: 'Rajasthan' },
    { id: 1121, city: 'Sawai Madhopur', state: 'Rajasthan' },
    { id: 1122, city: 'Shahpura', state: 'Rajasthan' },
    { id: 1123, city: 'Shahpura', state: 'Rajasthan' },
    { id: 1124, city: 'Sheoganj', state: 'Rajasthan' },
    { id: 1125, city: 'Sikar', state: 'Rajasthan' },
    { id: 1126, city: 'Sirohi', state: 'Rajasthan' },
    { id: 1127, city: 'Sojat', state: 'Rajasthan' },
    { id: 1128, city: 'Sri Madhopur', state: 'Rajasthan' },
    { id: 1129, city: 'Sujangarh', state: 'Rajasthan' },
    { id: 1130, city: 'Sumerpur', state: 'Rajasthan' },
    { id: 1131, city: 'Suratgarh', state: 'Rajasthan' },
    { id: 1132, city: 'Taranagar', state: 'Rajasthan' },
    { id: 1133, city: 'Todabhim', state: 'Rajasthan' },
    { id: 1134, city: 'Todaraisingh', state: 'Rajasthan' },
    { id: 1135, city: 'Tonk', state: 'Rajasthan' },
    { id: 1136, city: 'Udaipur', state: 'Rajasthan' },
    { id: 1137, city: 'Udaipurwati', state: 'Rajasthan' },
    { id: 1138, city: 'Vijainagar', state: 'Rajasthan' },
    { id: 1139, city: 'Gangtok', state: 'Sikkim' },
    { id: 1140, city: 'Calcutta', state: 'West Bengal' },
    { id: 1141, city: 'Arakkonam', state: 'Tamil Nadu' },
    { id: 1142, city: 'Arcot', state: 'Tamil Nadu' },
    { id: 1143, city: 'Aruppukkottai', state: 'Tamil Nadu' },
    { id: 1144, city: 'Bhavani', state: 'Tamil Nadu' },
    { id: 1145, city: 'Chengalpattu', state: 'Tamil Nadu' },
    { id: 1146, city: 'Chennai', state: 'Tamil Nadu' },
    { id: 1147, city: 'Chinna salem', state: 'Tamil nadu' },
    { id: 1148, city: 'Coimbatore', state: 'Tamil Nadu' },
    { id: 1149, city: 'Coonoor', state: 'Tamil Nadu' },
    { id: 1150, city: 'Cuddalore', state: 'Tamil Nadu' },
    { id: 1151, city: 'Dharmapuri', state: 'Tamil Nadu' },
    { id: 1152, city: 'Dindigul', state: 'Tamil Nadu' },
    { id: 1153, city: 'Erode', state: 'Tamil Nadu' },
    { id: 1154, city: 'Gudalur', state: 'Tamil Nadu' },
    { id: 1155, city: 'Gudalur', state: 'Tamil Nadu' },
    { id: 1156, city: 'Gudalur', state: 'Tamil Nadu' },
    { id: 1157, city: 'Kanchipuram', state: 'Tamil Nadu' },
    { id: 1158, city: 'Karaikudi', state: 'Tamil Nadu' },
    { id: 1159, city: 'Karungal', state: 'Tamil Nadu' },
    { id: 1160, city: 'Karur', state: 'Tamil Nadu' },
    { id: 1161, city: 'Kollankodu', state: 'Tamil Nadu' },
    { id: 1162, city: 'Lalgudi', state: 'Tamil Nadu' },
    { id: 1163, city: 'Madurai', state: 'Tamil Nadu' },
    { id: 1164, city: 'Nagapattinam', state: 'Tamil Nadu' },
    { id: 1165, city: 'Nagercoil', state: 'Tamil Nadu' },
    { id: 1166, city: 'Namagiripettai', state: 'Tamil Nadu' },
    { id: 1167, city: 'Namakkal', state: 'Tamil Nadu' },
    { id: 1168, city: 'Nandivaram-Guduvancheri', state: 'Tamil Nadu' },
    { id: 1169, city: 'Nanjikottai', state: 'Tamil Nadu' },
    { id: 1170, city: 'Natham', state: 'Tamil Nadu' },
    { id: 1171, city: 'Nellikuppam', state: 'Tamil Nadu' },
    { id: 1172, city: 'Neyveli', state: 'Tamil Nadu' },
    { id: 1173, city: 'O Valley', state: 'Tamil Nadu' },
    { id: 1174, city: 'Oddanchatram', state: 'Tamil Nadu' },
    { id: 1175, city: 'P.N.Patti', state: 'Tamil Nadu' },
    { id: 1176, city: 'Pacode', state: 'Tamil Nadu' },
    { id: 1177, city: 'Padmanabhapuram', state: 'Tamil Nadu' },
    { id: 1178, city: 'Palani', state: 'Tamil Nadu' },
    { id: 1179, city: 'Palladam', state: 'Tamil Nadu' },
    { id: 1180, city: 'Pallapatti', state: 'Tamil Nadu' },
    { id: 1181, city: 'Pallikonda', state: 'Tamil Nadu' },
    { id: 1182, city: 'Panagudi', state: 'Tamil Nadu' },
    { id: 1183, city: 'Panruti', state: 'Tamil Nadu' },
    { id: 1184, city: 'Paramakudi', state: 'Tamil Nadu' },
    { id: 1185, city: 'Parangipettai', state: 'Tamil Nadu' },
    { id: 1186, city: 'Pattukkottai', state: 'Tamil Nadu' },
    { id: 1187, city: 'Perambalur', state: 'Tamil Nadu' },
    { id: 1188, city: 'Peravurani', state: 'Tamil Nadu' },
    { id: 1189, city: 'Periyakulam', state: 'Tamil Nadu' },
    { id: 1190, city: 'Periyasemur', state: 'Tamil Nadu' },
    { id: 1191, city: 'Pernampattu', state: 'Tamil Nadu' },
    { id: 1192, city: 'Pollachi', state: 'Tamil Nadu' },
    { id: 1193, city: 'Polur', state: 'Tamil Nadu' },
    { id: 1194, city: 'Ponneri', state: 'Tamil Nadu' },
    { id: 1195, city: 'Pudukkottai', state: 'Tamil Nadu' },
    { id: 1196, city: 'Pudupattinam', state: 'Tamil Nadu' },
    { id: 1197, city: 'Puliyankudi', state: 'Tamil Nadu' },
    { id: 1198, city: 'Punjaipugalur', state: 'Tamil Nadu' },
    { id: 1199, city: 'Rajapalayam', state: 'Tamil Nadu' },
    { id: 1200, city: 'Ramanathapuram', state: 'Tamil Nadu' },
    { id: 1201, city: 'Rameshwaram', state: 'Tamil Nadu' },
    { id: 1202, city: 'Rasipuram', state: 'Tamil Nadu' },
    { id: 1203, city: 'Salem', state: 'Tamil Nadu' },
    { id: 1204, city: 'Sankarankoil', state: 'Tamil Nadu' },
    { id: 1205, city: 'Sankari', state: 'Tamil Nadu' },
    { id: 1206, city: 'Sathyamangalam', state: 'Tamil Nadu' },
    { id: 1207, city: 'Sattur', state: 'Tamil Nadu' },
    { id: 1208, city: 'Shenkottai', state: 'Tamil Nadu' },
    { id: 1209, city: 'Sholavandan', state: 'Tamil Nadu' },
    { id: 1210, city: 'Sholingur', state: 'Tamil Nadu' },
    { id: 1211, city: 'Sirkali', state: 'Tamil Nadu' },
    { id: 1212, city: 'Sivaganga', state: 'Tamil Nadu' },
    { id: 1213, city: 'Sivagiri', state: 'Tamil Nadu' },
    { id: 1214, city: 'Sivakasi', state: 'Tamil Nadu' },
    { id: 1215, city: 'Srivilliputhur', state: 'Tamil Nadu' },
    { id: 1216, city: 'Surandai', state: 'Tamil Nadu' },
    { id: 1217, city: 'Suriyampalayam', state: 'Tamil Nadu' },
    { id: 1218, city: 'Tenkasi', state: 'Tamil Nadu' },
    { id: 1219, city: 'Thammampatti', state: 'Tamil Nadu' },
    { id: 1220, city: 'Thanjavur', state: 'Tamil Nadu' },
    { id: 1221, city: 'Tharamangalam', state: 'Tamil Nadu' },
    { id: 1222, city: 'Tharangambadi', state: 'Tamil Nadu' },
    { id: 1223, city: 'Theni Allinagaram', state: 'Tamil Nadu' },
    { id: 1224, city: 'Thirumangalam', state: 'Tamil Nadu' },
    { id: 1225, city: 'Thirunindravur', state: 'Tamil Nadu' },
    { id: 1226, city: 'Thiruparappu', state: 'Tamil Nadu' },
    { id: 1227, city: 'Thirupuvanam', state: 'Tamil Nadu' },
    { id: 1228, city: 'Thiruthuraipoondi', state: 'Tamil Nadu' },
    { id: 1229, city: 'Thiruvallur', state: 'Tamil Nadu' },
    { id: 1230, city: 'Thiruvarur', state: 'Tamil Nadu' },
    { id: 1231, city: 'Thoothukudi', state: 'Tamil Nadu' },
    { id: 1232, city: 'Thuraiyur', state: 'Tamil Nadu' },
    { id: 1233, city: 'Tindivanam', state: 'Tamil Nadu' },
    { id: 1234, city: 'Tiruchendur', state: 'Tamil Nadu' },
    { id: 1235, city: 'Tiruchengode', state: 'Tamil Nadu' },
    { id: 1236, city: 'Tiruchirappalli', state: 'Tamil Nadu' },
    { id: 1237, city: 'Tirukalukundram', state: 'Tamil Nadu' },
    { id: 1238, city: 'Tirukkoyilur', state: 'Tamil Nadu' },
    { id: 1239, city: 'Tirunelveli', state: 'Tamil Nadu' },
    { id: 1240, city: 'Tirupathur', state: 'Tamil Nadu' },
    { id: 1241, city: 'Tirupathur', state: 'Tamil Nadu' },
    { id: 1242, city: 'Tiruppur', state: 'Tamil Nadu' },
    { id: 1243, city: 'Tiruttani', state: 'Tamil Nadu' },
    { id: 1244, city: 'Tiruvannamalai', state: 'Tamil Nadu' },
    { id: 1245, city: 'Tiruvethipuram', state: 'Tamil Nadu' },
    { id: 1246, city: 'Tittakudi', state: 'Tamil Nadu' },
    { id: 1247, city: 'Udhagamandalam', state: 'Tamil Nadu' },
    { id: 1248, city: 'Udumalaipettai', state: 'Tamil Nadu' },
    { id: 1249, city: 'Unnamalaikadai', state: 'Tamil Nadu' },
    { id: 1250, city: 'Usilampatti', state: 'Tamil Nadu' },
    { id: 1251, city: 'Uthamapalayam', state: 'Tamil Nadu' },
    { id: 1252, city: 'Uthiramerur', state: 'Tamil Nadu' },
    { id: 1253, city: 'Vadakkuvalliyur', state: 'Tamil Nadu' },
    { id: 1254, city: 'Vadalur', state: 'Tamil Nadu' },
    { id: 1255, city: 'Vadipatti', state: 'Tamil Nadu' },
    { id: 1256, city: 'Valparai', state: 'Tamil Nadu' },
    { id: 1257, city: 'Vandavasi', state: 'Tamil Nadu' },
    { id: 1258, city: 'Vaniyambadi', state: 'Tamil Nadu' },
    { id: 1259, city: 'Vedaranyam', state: 'Tamil Nadu' },
    { id: 1260, city: 'Vellakoil', state: 'Tamil Nadu' },
    { id: 1261, city: 'Vellore', state: 'Tamil Nadu' },
    { id: 1262, city: 'Vikramasingapuram', state: 'Tamil Nadu' },
    { id: 1263, city: 'Viluppuram', state: 'Tamil Nadu' },
    { id: 1264, city: 'Virudhachalam', state: 'Tamil Nadu' },
    { id: 1265, city: 'Virudhunagar', state: 'Tamil Nadu' },
    { id: 1266, city: 'Viswanatham', state: 'Tamil Nadu' },
    { id: 1267, city: 'Agartala', state: 'Tripura' },
    { id: 1268, city: 'Badharghat', state: 'Tripura' },
    { id: 1269, city: 'Dharmanagar', state: 'Tripura' },
    { id: 1270, city: 'Indranagar', state: 'Tripura' },
    { id: 1271, city: 'Jogendranagar', state: 'Tripura' },
    { id: 1272, city: 'Kailasahar', state: 'Tripura' },
    { id: 1273, city: 'Khowai', state: 'Tripura' },
    { id: 1274, city: 'Pratapgarh', state: 'Tripura' },
    { id: 1275, city: 'Udaipur', state: 'Tripura' },
    { id: 1276, city: 'Achhnera', state: 'Uttar Pradesh' },
    { id: 1277, city: 'Adari', state: 'Uttar Pradesh' },
    { id: 1278, city: 'Agra', state: 'Uttar Pradesh' },
    { id: 1279, city: 'Aligarh', state: 'Uttar Pradesh' },
    { id: 1280, city: 'Allahabad', state: 'Uttar Pradesh' },
    { id: 1281, city: 'Amroha', state: 'Uttar Pradesh' },
    { id: 1282, city: 'Azamgarh', state: 'Uttar Pradesh' },
    { id: 1283, city: 'Bahraich', state: 'Uttar Pradesh' },
    { id: 1284, city: 'Ballia', state: 'Uttar Pradesh' },
    { id: 1285, city: 'Balrampur', state: 'Uttar Pradesh' },
    { id: 1286, city: 'Banda', state: 'Uttar Pradesh' },
    { id: 1287, city: 'Bareilly', state: 'Uttar Pradesh' },
    { id: 1288, city: 'Chandausi', state: 'Uttar Pradesh' },
    { id: 1289, city: 'Dadri', state: 'Uttar Pradesh' },
    { id: 1290, city: 'Deoria', state: 'Uttar Pradesh' },
    { id: 1291, city: 'Etawah', state: 'Uttar Pradesh' },
    { id: 1292, city: 'Fatehabad', state: 'Uttar Pradesh' },
    { id: 1293, city: 'Fatehpur', state: 'Uttar Pradesh' },
    { id: 1294, city: 'Fatehpur', state: 'Uttar Pradesh' },
    { id: 1295, city: 'Greater No', state: 'Uttar Pradesh' },
    { id: 1296, city: 'Hamirpur', state: 'Uttar Pradesh' },
    { id: 1297, city: 'Hardoi', state: 'Uttar Pradesh' },
    { id: 1298, city: 'Jajmau', state: 'Uttar Pradesh' },
    { id: 1299, city: 'Jaunpur', state: 'Uttar Pradesh' },
    { id: 1300, city: 'Jhansi', state: 'Uttar Pradesh' },
    { id: 1301, city: 'Kalpi', state: 'Uttar Pradesh' },
    { id: 1302, city: 'Kanpur', state: 'Uttar Pradesh' },
    { id: 1303, city: 'Kota', state: 'Uttar Pradesh' },
    { id: 1304, city: 'Laharpur', state: 'Uttar Pradesh' },
    { id: 1305, city: 'Lakhimpur', state: 'Uttar Pradesh' },
    { id: 1306, city: 'Lal Gopalganj Nindaura', state: 'Uttar Pradesh' },
    { id: 1307, city: 'Lalganj', state: 'Uttar Pradesh' },
    { id: 1308, city: 'Lalitpur', state: 'Uttar Pradesh' },
    { id: 1309, city: 'Lar', state: 'Uttar Pradesh' },
    { id: 1310, city: 'Loni', state: 'Uttar Pradesh' },
    { id: 1311, city: 'Lucknow', state: 'Uttar Pradesh' },
    { id: 1312, city: 'Mathura', state: 'Uttar Pradesh' },
    { id: 1313, city: 'Meerut', state: 'Uttar Pradesh' },
    { id: 1314, city: 'Modinagar', state: 'Uttar Pradesh' },
    { id: 1315, city: 'Muradnagar', state: 'Uttar Pradesh' },
    { id: 1316, city: 'Nagina', state: 'Uttar Pradesh' },
    { id: 1317, city: 'Najibabad', state: 'Uttar Pradesh' },
    { id: 1318, city: 'Nakur', state: 'Uttar Pradesh' },
    { id: 1319, city: 'Nanpara', state: 'Uttar Pradesh' },
    { id: 1320, city: 'Naraura', state: 'Uttar Pradesh' },
    { id: 1321, city: 'Naugawan Sadat', state: 'Uttar Pradesh' },
    { id: 1322, city: 'Nautanwa', state: 'Uttar Pradesh' },
    { id: 1323, city: 'Nawabganj', state: 'Uttar Pradesh' },
    { id: 1324, city: 'Nehtaur', state: 'Uttar Pradesh' },
    { id: 1325, city: 'Noorpur', state: 'Uttar Pradesh' },
    { id: 1326, city: 'Obra', state: 'Uttar Pradesh' },
    { id: 1327, city: 'Orai', state: 'Uttar Pradesh' },
    { id: 1328, city: 'Padrauna', state: 'Uttar Pradesh' },
    { id: 1329, city: 'Palia Kalan', state: 'Uttar Pradesh' },
    { id: 1330, city: 'Parasi', state: 'Uttar Pradesh' },
    { id: 1331, city: 'Phulpur', state: 'Uttar Pradesh' },
    { id: 1332, city: 'Pihani', state: 'Uttar Pradesh' },
    { id: 1333, city: 'Pilibhit', state: 'Uttar Pradesh' },
    { id: 1334, city: 'Pilkhuwa', state: 'Uttar Pradesh' },
    { id: 1335, city: 'Powayan', state: 'Uttar Pradesh' },
    { id: 1336, city: 'Pukhrayan', state: 'Uttar Pradesh' },
    { id: 1337, city: 'Puranpur', state: 'Uttar Pradesh' },
    { id: 1338, city: 'Purquazi', state: 'Uttar Pradesh' },
    { id: 1339, city: 'Purwa', state: 'Uttar Pradesh' },
    { id: 1340, city: 'Rae Bareli', state: 'Uttar Pradesh' },
    { id: 1341, city: 'Rampur', state: 'Uttar Pradesh' },
    { id: 1342, city: 'Rampur Maniharan', state: 'Uttar Pradesh' },
    { id: 1343, city: 'Rasra', state: 'Uttar Pradesh' },
    { id: 1344, city: 'Rath', state: 'Uttar Pradesh' },
    { id: 1345, city: 'Renukoot', state: 'Uttar Pradesh' },
    { id: 1346, city: 'Reoti', state: 'Uttar Pradesh' },
    { id: 1347, city: 'Robertsganj', state: 'Uttar Pradesh' },
    { id: 1348, city: 'Rudauli', state: 'Uttar Pradesh' },
    { id: 1349, city: 'Rudrapur', state: 'Uttar Pradesh' },
    { id: 1350, city: 'Sadabad', state: 'Uttar Pradesh' },
    { id: 1351, city: 'Safipur', state: 'Uttar Pradesh' },
    { id: 1352, city: 'Saharanpur', state: 'Uttar Pradesh' },
    { id: 1353, city: 'Sahaspur', state: 'Uttar Pradesh' },
    { id: 1354, city: 'Sahaswan', state: 'Uttar Pradesh' },
    { id: 1355, city: 'Sahawar', state: 'Uttar Pradesh' },
    { id: 1356, city: 'Sahjanwa', state: 'Uttar Pradesh' },
    { id: 1357, city: 'Sar', state: ' Ghazipur' },
    { id: 1358, city: 'Sambhal', state: 'Uttar Pradesh' },
    { id: 1359, city: 'Samdhan', state: 'Uttar Pradesh' },
    { id: 1360, city: 'Samthar', state: 'Uttar Pradesh' },
    { id: 1361, city: 'Sandi', state: 'Uttar Pradesh' },
    { id: 1362, city: 'Sandila', state: 'Uttar Pradesh' },
    { id: 1363, city: 'Sardhana', state: 'Uttar Pradesh' },
    { id: 1364, city: 'Seohara', state: 'Uttar Pradesh' },
    { id: 1365, city: 'Shahabad', state: ' Hardoi' },
    { id: 1366, city: 'Shahabad', state: ' Rampur' },
    { id: 1367, city: 'Shahganj', state: 'Uttar Pradesh' },
    { id: 1368, city: 'Shahjahanpur', state: 'Uttar Pradesh' },
    { id: 1369, city: 'Shamli', state: 'Uttar Pradesh' },
    { id: 1370, city: 'Shamsabad', state: ' Agra' },
    { id: 1371, city: 'Shamsabad', state: ' Farrukhabad' },
    { id: 1372, city: 'Sherkot', state: 'Uttar Pradesh' },
    { id: 1373, city: 'Shikarpur', state: ' Bulandshahr' },
    { id: 1374, city: 'Shikohabad', state: 'Uttar Pradesh' },
    { id: 1375, city: 'Shishgarh', state: 'Uttar Pradesh' },
    { id: 1376, city: 'Siana', state: 'Uttar Pradesh' },
    { id: 1377, city: 'Sikanderpur', state: 'Uttar Pradesh' },
    { id: 1378, city: 'Sikandra Rao', state: 'Uttar Pradesh' },
    { id: 1379, city: 'Sikandrabad', state: 'Uttar Pradesh' },
    { id: 1380, city: 'Sirsaganj', state: 'Uttar Pradesh' },
    { id: 1381, city: 'Sirsi', state: 'Uttar Pradesh' },
    { id: 1382, city: 'Sitapur', state: 'Uttar Pradesh' },
    { id: 1383, city: 'Soron', state: 'Uttar Pradesh' },
    { id: 1384, city: 'Suar', state: 'Uttar Pradesh' },
    { id: 1385, city: 'Sultanpur', state: 'Uttar Pradesh' },
    { id: 1386, city: 'Sumerpur', state: 'Uttar Pradesh' },
    { id: 1387, city: 'Tanda', state: 'Uttar Pradesh' },
    { id: 1388, city: 'Tanda', state: 'Uttar Pradesh' },
    { id: 1389, city: 'Tetri Bazar', state: 'Uttar Pradesh' },
    { id: 1390, city: 'Thakurdwara', state: 'Uttar Pradesh' },
    { id: 1391, city: 'Thana Bhawan', state: 'Uttar Pradesh' },
    { id: 1392, city: 'Tilhar', state: 'Uttar Pradesh' },
    { id: 1393, city: 'Tirwaganj', state: 'Uttar Pradesh' },
    { id: 1394, city: 'Tulsipur', state: 'Uttar Pradesh' },
    { id: 1395, city: 'Tundla', state: 'Uttar Pradesh' },
    { id: 1396, city: 'Unnao', state: 'Uttar Pradesh' },
    { id: 1397, city: 'Utraula', state: 'Uttar Pradesh' },
    { id: 1398, city: 'Varanasi', state: 'Uttar Pradesh' },
    { id: 1399, city: 'Vrindavan', state: 'Uttar Pradesh' },
    { id: 1400, city: 'Warhapur', state: 'Uttar Pradesh' },
    { id: 1401, city: 'Zar', state: 'Uttar Pradesh' },
    { id: 1402, city: 'Zamania', state: 'Uttar Pradesh' },
    { id: 1403, city: 'Almora', state: 'Uttarakhand' },
    { id: 1404, city: 'Bazpur', state: 'Uttarakhand' },
    { id: 1405, city: 'Chamba', state: 'Uttarakhand' },
    { id: 1406, city: 'Dehradun', state: 'Uttarakhand' },
    { id: 1407, city: 'Haldwani', state: 'Uttarakhand' },
    { id: 1408, city: 'Harr', state: 'Uttarakhand' },
    { id: 1409, city: 'Jaspur', state: 'Uttarakhand' },
    { id: 1410, city: 'Kashipur', state: 'Uttarakhand' },
    { id: 1411, city: 'kichha', state: 'Uttarakhand' },
    { id: 1412, city: 'Kotdwara', state: 'Uttarakhand' },
    { id: 1413, city: 'Manglaur', state: 'Uttarakhand' },
    { id: 1414, city: 'Mussoorie', state: 'Uttarakhand' },
    { id: 1415, city: 'Nagla', state: 'Uttarakhand' },
    { id: 1416, city: 'Nainital', state: 'Uttarakhand' },
    { id: 1417, city: 'Pauri', state: 'Uttarakhand' },
    { id: 1418, city: 'Pithoragarh', state: 'Uttarakhand' },
    { id: 1419, city: 'Ramnagar', state: 'Uttarakhand' },
    { id: 1420, city: 'Rishikesh', state: 'Uttarakhand' },
    { id: 1421, city: 'Roorkee', state: 'Uttarakhand' },
    { id: 1422, city: 'Rudrapur', state: 'Uttarakhand' },
    { id: 1423, city: 'Sitarganj', state: 'Uttarakhand' },
    { id: 1424, city: 'Tehri', state: 'Uttarakhand' },
    { id: 1425, city: 'Muzaffarnagar', state: 'Uttar Pradesh' },
    { id: 1426, city: 'Adra', state: ' Purulia' },
    { id: 1427, city: 'Alipurduar', state: 'West Bengal' },
    { id: 1428, city: 'Arambagh', state: 'West Bengal' },
    { id: 1429, city: 'Asansol', state: 'West Bengal' },
    { id: 1430, city: 'Baharampur', state: 'West Bengal' },
    { id: 1431, city: 'Bally', state: 'West Bengal' },
    { id: 1432, city: 'Balurghat', state: 'West Bengal' },
    { id: 1433, city: 'Bankura', state: 'West Bengal' },
    { id: 1434, city: 'Barakar', state: 'West Bengal' },
    { id: 1435, city: 'Barasat', state: 'West Bengal' },
    { id: 1436, city: 'Bardhaman', state: 'West Bengal' },
    { id: 1437, city: 'Bn Nagar', state: 'West Bengal' },
    { id: 1438, city: 'Chinsura', state: 'West Bengal' },
    { id: 1439, city: 'Contai', state: 'West Bengal' },
    { id: 1440, city: 'Cooch Behar', state: 'West Bengal' },
    { id: 1441, city: 'Darjeeling', state: 'West Bengal' },
    { id: 1442, city: 'Durgapur', state: 'West Bengal' },
    { id: 1443, city: 'Haldia', state: 'West Bengal' },
    { id: 1444, city: 'Howrah', state: 'West Bengal' },
    { id: 1445, city: 'Islampur', state: 'West Bengal' },
    { id: 1446, city: 'Jhargram', state: 'West Bengal' },
    { id: 1447, city: 'Kharagpur', state: 'West Bengal' },
    { id: 1448, city: 'Kolkata', state: 'West Bengal' },
    { id: 1449, city: 'Mainaguri', state: 'West Bengal' },
    { id: 1450, city: 'Mal', state: 'West Bengal' },
    { id: 1451, city: 'Mathabhanga', state: 'West Bengal' },
    { id: 1452, city: 'Medinipur', state: 'West Bengal' },
    { id: 1453, city: 'Memari', state: 'West Bengal' },
    { id: 1454, city: 'Monoharpur', state: 'West Bengal' },
    { id: 1455, city: 'Murshad', state: 'West Bengal' },
    { id: 1456, city: 'Nabadwip', state: 'West Bengal' },
    { id: 1457, city: 'Naihati', state: 'West Bengal' },
    { id: 1458, city: 'Panchla', state: 'West Bengal' },
    { id: 1459, city: 'Pandua', state: 'West Bengal' },
    { id: 1460, city: 'Paschim Punropara', state: 'West Bengal' },
    { id: 1461, city: 'Purulia', state: 'West Bengal' },
    { id: 1462, city: 'Raghunathpur', state: 'West Bengal' },
    { id: 1463, city: 'Raiganj', state: 'West Bengal' },
    { id: 1464, city: 'Rampurhat', state: 'West Bengal' },
    { id: 1465, city: 'Ranaghat', state: 'West Bengal' },
    { id: 1466, city: 'Sainthia', state: 'West Bengal' },
    { id: 1467, city: 'Santipur', state: 'West Bengal' },
    { id: 1468, city: 'Siliguri', state: 'West Bengal' },
    { id: 1469, city: 'Sonamukhi', state: 'West Bengal' },
    { id: 1470, city: 'Srirampore', state: 'West Bengal' },
    { id: 1471, city: 'Suri', state: 'West Bengal' },
    { id: 1472, city: 'Taki', state: 'West Bengal' },
    { id: 1473, city: 'Tamluk', state: 'West Bengal' },
    { id: 1474, city: 'Tarakeswar', state: 'West Bengal' },
    { id: 1475, city: 'Chikmagalur', state: 'Karnataka' },
    { id: 1476, city: 'Davanagere', state: 'Karnataka' },
    { id: 1477, city: 'Dharwad', state: 'Karnataka' },
    { id: 1478, city: 'Gadag', state: 'Karnataka' },
    { id: 1479, city: 'Chennai', state: 'Tamil Nadu' },
    { id: 1480, city: 'Coimbatore', state: 'Tamil Nadu' },

    { id: 1481, city: 'Manjeri', state: 'Kerala' },
    { id: 1482, city: 'Kottakal', state: 'Kerala' },
    { id: 1483, city: 'Munnar', state: 'Kerala' },
    { id: 1484, city: 'Idukki', state: 'Kerala' },
    { id: 1485, city: 'Kanjoor', state: 'Kerala' },
    { id: 1486, city: 'Wayanad', state: 'Kerala' },
    { id: 1487, city: 'Kambilikandam', state: 'Tamil Nadu' },
    { id: 1488, city: 'Thavinhal', state: 'Kerala' },
    { id: 1489, city: 'Nagalur', state: 'Tamil Nadu' },
    { id: 1490, city: 'Kodaikanal', state: 'Tamil Nadu' },
    { id: 1491, city: 'Kotagiri', state: 'Tamil Nadu' },
]

export default cities